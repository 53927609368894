import React, { useEffect, useState } from "react";
import TotalSales from "./TotalSales";
import TransFre from "./TransFreq";
import SalesTrend from "./SalesTrend";
import SalesChart from "./SalesChart";
import RealtimeStatus from "./RealtimeStatus";
import MachinePerTable from "./MachinePerTable";
import ProductsPerTable from "./PorductsPerTable";

import { Tab, Tabs } from "react-bootstrap";
import DateRangeDropdown from "./DateRangeDropdown";

import "../components/css/MachinePerformance.css";
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { format } from "date-fns";


const MachinePerformance = () => {
    const today = new Date();
    const defaultDisplayDate = format(today, "dd MMM");
    const defaultReturnDate = format(today, "yyyy-MM-dd");

    const [selectedRange, setSelectedRange] = useState("Today");
    const [dateRange, setDateRange] = useState(defaultDisplayDate);
    const [formattedDateRange, setFormattedDateRange] = useState(`${defaultReturnDate}/${defaultReturnDate}`); // New state for return format
    const [totalTransaction, setTotalTransaction] = useState("");
    const [totalFreq, setTotalFreq] = useState("");
    const [salesTrend, setSalesTrend] = useState([]);
    const [salesChart, setSalesChart] = useState([]);
    const [machineRanking, setMachineRanking] = useState([]);
    const [productRanking, setProductRanking] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                getData();
            }
        });
    
        return () => checkAuth();
    }, []);

    //get all data
    const getData = async () => {
        setIsLoading(true);
        try {
            await Promise.all([
                getMachineRankingData(),
                getProductRankingData(),
                getTotalSalesTotalFreq(),
                getSaleTrend(),
                getSalesChart(),
            ]);
        } catch (error) {
            console.log("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    //get total sales and total frequency
    const getTotalSalesTotalFreq = async () => {
        try {
            let token =  await waitForToken();

            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/machine_transaction/transactionAndFrequency`, {
                params: {
                    date: formattedDateRange,
                    status: 1,
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            
            setTotalTransaction(res.data.result.data.totalTransaction ?? "0");
            setTotalFreq(res.data.result.data.totalFrequency ?? "0");
        } catch (err) {
            console.log('Error fetching data:', err);
        }
    };

    //get sales trend data
    const getSaleTrend = async () => {
        try {
            let token =  await waitForToken();

            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/machine_transaction/salesTrend`, {
                params: {
                    date: formattedDateRange,
                    status: 1,
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            setSalesTrend(res.data.result.data);
        } catch (err) {
            console.log('Error fetching data:', err);
        }
    };

    //get sales chart data
    const getSalesChart = async () => {
        try {
            let token =  await waitForToken();

            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/machine_transaction/salesChart`, {
                params: {
                    date: formattedDateRange,
                    status: 1,
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            let datas = res.data.result.data;

            setSalesChart(datas);
        } catch (err) {
            console.log('Error fetching data:', err);
        }
    };

    // get machine ranking data
    const getMachineRankingData = async () => {
        try {
            let token =  await waitForToken();

            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/machine_transaction/salesMachine`, {
                params: {
                    date: formattedDateRange, //pass dropdown value to here
                    status: 1, //success
                    limit: 5, //select top 5
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            setMachineRanking(res.data.result.data);
        } catch (err) {
            console.log('Error fetching data:', err);
        }
    };

    //get product ranking data
    const getProductRankingData = async () => {
        try {
            let token =  await waitForToken();

            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/machine_transaction/salesProduct`, {
                params: {
                    date: formattedDateRange, //pass dropdown value to here
                    status: 1, //success
                    limit: 5, //select top 5
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            setProductRanking(res.data.result.data);
        } catch (err) {
            console.log('Error fetching data:', err);
        }
    };

    const waitForToken = async (timeout = 5000) => {
        return new Promise((resolve, reject) => {
            let timeElapsed = 0;
            const interval = 200; // Check every 200ms
            
            const checkToken = setInterval(() => {
                const token = localStorage.getItem("token");
                if (token) {
                    clearInterval(checkToken);
                    resolve(token);
                }
                timeElapsed += interval;
                if (timeElapsed >= timeout) {
                    clearInterval(checkToken);
                    reject("Token not found within timeout!");
                }
            }, interval);
        });
    };

    useEffect(() => {
        if(formattedDateRange) {
            getData();
        }
    }, [formattedDateRange]);

    return(
        <>
            <div className="machineArea"> 
                <div className="area"> {/* 60% */}
                    <div className="realtimeArea">
                        <div className="machineTitle">
                            <div>
                                <h4 className="lblRealtime">Machines Performance</h4>
                            </div>
                            <div>
                                <div style={{ display:"flex", alignItems:"center"}}>
                                    <DateRangeDropdown
                                        selectedRange={selectedRange} 
                                        setSelectedRange={setSelectedRange}
                                        dateRange={dateRange} 
                                        setDateRange={setDateRange}
                                        setFormattedDateRange={setFormattedDateRange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="handleSaleTrans">
                            <TotalSales totalTransaction={totalTransaction} />
                            <TransFre totalFreq={totalFreq} />
                        </div>
                        <div className="">
                            <Tabs defaultActiveKey="salesTrend" id="sales-tabs" className="mb-3">
                                <Tab eventKey="salesTrend" title="Sales Trend">
                                    <SalesChart salesTrend={salesTrend} />
                                </Tab>
                                <Tab eventKey="salesChart" title="Sales Chart">
                                    <SalesTrend salesChart={salesChart} />
                                </Tab>
                            </Tabs>
                        </div>
                        <div className="handleSaleCharts">
                            <MachinePerTable machineRanking={machineRanking} isLoading={isLoading} />
                            <ProductsPerTable productRanking={productRanking} isLoading={isLoading} />
                        </div>
                    </div>
                </div>
                <div className="area">
                    <RealtimeStatus />
                </div>
            </div>
        </>
    )
};

export default MachinePerformance;