import { CardHeader, CardContent, Grid, CardActions, Button, Divider, Typography, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useState } from 'react'
import { AutocompleteInput, BooleanInput, required, SelectInput, SimpleForm, ReferenceInput, TextInput, useDataProvider, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        flexDirection: 'column', // Align checkboxes side-by-side
        gap: theme.spacing(3), // Add spacing between checkboxes
    },
    formControlLabel: {
        marginRight: theme.spacing(2), // Adjust the spacing between labels and checkboxes if needed
    },
    alignWithTitle: {
        marginLeft: theme.spacing(2), // Adjust this to match the title alignment
    },
}));

const VENDING_MACHINE_MODELS = [
    'VE-E200C',
    'VE-E200N',
    'VE-E300C',
    'VE-E300N',
    'VE-E400C',
    'VE-E400N',
    'VE-E1208C',
    'VE-E1208N',
    'VE-CMC0500',
    'VE-IND0500',
    'VE-IND0400-GBS18*01',
    'E500C',
    'E500N',
    'E600'
];

const MACHINE = {
    MODE: {
        COMMERCIAL: 'commercial',
        MRO: 'mro',
    },
};

const ERROR_STATUS = {
    BILL_ERROR: "bill_error",
    COIN_ERROR: "coin_error",
};


const MachineSettings = (props) => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const classes = useStyles();
    const navigate = useNavigate();

    const [mode, setMode] = useState("");
    const [removing, setRemoving] = useState(false);
    const [paymentEnabled, setPaymentEnabled] = useState(props.macDetails.paymentEnabled || []);
    const [errorStatusEnabled, setErrorStatusEnabled] = useState(
        Object.values(ERROR_STATUS).filter((error) => !!props.macDetails?.status?.[error])
    );

    const removeMachine = async () => {
        setRemoving(true);
        try {
            await dataProvider.update("machines", {
                id: props.macDetails.id,
                data: { isDeleted: true }
            });
            alert("Successfully removed machine.");
            redirect("/machines");
        } catch (err) {
            console.error(err);
            alert("Error removing machine");
        } finally {
            setRemoving(false);
        }
    };

    const handleModeChange = (e) => {
        setMode(e.target.value)
    };

    const validatedMachineModel = VENDING_MACHINE_MODELS.includes(props.macDetails?.machineModel)
    ? props.macDetails.machineModel
    : ''; // Set to empty string if not a valid value

    const handleErrorStatusChange = (event) => {
        const { name, checked } = event.target;
        setErrorStatusEnabled((prev) => {
            if (checked) {
                // Add the item to the array if checked
                return [...prev, name];
            } else {
                // Remove the item from the array if unchecked
                return prev.filter((item) => item !== name);
            }
        });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPaymentEnabled((prev) => {
            if (checked) {
                // Add the item to the array if checked
                return [...prev, name];
            } else {
                // Remove the item from the array if unchecked
                return prev.filter((item) => item !== name);
            }
        });
    };

    const handleSave = async (datas) => {
        if(paymentEnabled.includes("iPay88") && paymentEnabled.includes("ghl")) {
            alert("Ipay88 and GHL cannot be enabled at the same time.");
            return;
        }
        
        const isBillErrorSelected = errorStatusEnabled.includes(ERROR_STATUS.BILL_ERROR);
        const isCoinErrorSelected = errorStatusEnabled.includes(ERROR_STATUS.COIN_ERROR);

        const newStatus = { ...props.macDetails.status };
        
        if (!isBillErrorSelected) {
            delete newStatus[ERROR_STATUS.BILL_ERROR];
        }
        if (!isCoinErrorSelected) {
            delete newStatus[ERROR_STATUS.COIN_ERROR];
        }

        let dataObj = {
            machineUUID: datas.machineUUID,
            machineModel: datas.machineModel,
            machineMode: datas.machineMode,
            machineName: datas.machineName,
            location: datas.location,
            dropSensor: datas.dropSensor,
            "client.id": datas.client.id,
            enableExtraSpin: datas.enableExtraSpin ? true : false,
            mroOneProduct: datas.mroOneProduct ? true : false,
            "paymentMethods.ghl.key": datas?.paymentMethods?.ghl?.key || "",
            "paymentMethods.ghl.merchantID": datas.paymentMethods?.ghl?.merchantID || "",
            "paymentMethods.ghl.terminalID": datas.paymentMethods?.ghl?.terminalID || "",
            paymentEnabled: paymentEnabled,
            status: newStatus,
        }

        try {
            const machineRef = doc(db, "machines", props.id);

            await updateDoc(machineRef, dataObj);

            alert("Machine data successfully updated");
            navigate("/machines");
        } catch (err) {
            console.error("Error updating machine document:", err);
        }

    };

    return (
        <>
            <div style={{ width: '90%' }}>
                <CardHeader title="Vending Machine Details" />
                <CardContent>
                    {props.macDetails ? (
                        <SimpleForm toolbar={null} onSubmit={handleSave}>
                            <Grid container spacing={3}>
                                <Grid container item spacing={3} xs={8}>
                                    <Grid item xs={12}>
                                        <TextInput
                                            source="machineUUID"
                                            label="Machine UUID"
                                            disabled
                                            fullWidth
                                            defaultValue={props.macDetails.machineUUID}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectInput
                                            source="machineModel"
                                            disabled={!props.isAdmin}
                                            fullWidth
                                            choices={VENDING_MACHINE_MODELS.map(model => ({ id: model, name: model }))}
                                            defaultValue={validatedMachineModel}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectInput
                                            source="machineMode"
                                            disabled={!props.isAdmin}
                                            fullWidth
                                            choices={Object.values(MACHINE.MODE).map(v => ({ id: v, name: v, }))}
                                            validate={required("Please select a mode")}
                                            onChange={handleModeChange}
                                            defaultValue={props.macDetails.machineMode}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            source="machineName"
                                            label="Machine Name"
                                            fullWidth
                                            defaultValue={props.macDetails?.machineName || ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            source="location"
                                            fullWidth
                                            defaultValue={props.macDetails.location}
                                        />
                                    </Grid>
                                    {props.isAdmin ? (
                                        <Grid item xs={12}>
                                            <ReferenceInput
                                                source="client.id"
                                                label="Client"
                                                reference="clients"
                                                filterToQuery={() => {}}
                                                perPage={9999}
                                                fullWidth
                                            >
                                                <AutocompleteInput optionText="companyName" validate={required("Please select a client.")} />
                                            </ReferenceInput>
                                            <TextInput
                                                source="paymentMethods.ipay88"
                                                defaultValue={props.macDetails.client.id}
                                                style={{ display: "none" }}
                                            />
                                        </Grid>
                                    ) : (
                                        <TextInput
                                            source="client.id"
                                            defaultValue={props.macDetails.client.id}
                                            style={{ display: "none" }}
                                        />
                                    )}
                                </Grid>
                                <Grid container item spacing={3} xs={4} alignItems="flex-start" alignContent="flex-start">
                                    <Typography variant="h6">Error Status</Typography>
                                    <Grid container direction='column' spacing={2} style={{ margin: "1rem 0 0 1rem" }}>
                                        <FormGroup className={classes.formGroup}>
                                            {Object.entries(ERROR_STATUS).map(([key, value]) => (
                                                <FormControlLabel
                                                    key={key}
                                                    control={
                                                        <Checkbox
                                                            name={value}
                                                            checked={errorStatusEnabled.includes(value)}
                                                            onChange={handleErrorStatusChange}
                                                            disabled={!props.macDetails?.status?.[value]}
                                                            defaultChecked={!!props.macDetails?.status?.[value]}
                                                        />
                                                    }
                                                    label={key.replace('_', ' ').toLowerCase()}
                                                />
                                            ))}
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BooleanInput source="dropSensor" defaultValue={props.macDetails.dropSensor} />
                                    </Grid>
                                    {props.isAdmin ? (
                                        <Grid item xs={12}>
                                            <BooleanInput source="enableExtraSpin" defaultValue={props.macDetails.enableExtraSpin} />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                    {props.isAdmin && (mode === "mro" || props.macDetails.machineMode == "mro") && (
                                        <>
                                            <Grid item xs={12}>
                                                <BooleanInput source="mroOneProduct" defaultValue={props.macDetails.mroOneProduct} />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                
                                {props.macDetails.machineMode === "commercial" && (
                                    <>
                                        {props.macDetails.paymentEnabled.includes("ghl") && (
                                            <>
                                                <CardHeader title="GHL Account Details" />
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={8}>
                                                            <TextInput
                                                                source="paymentMethods.ghl.key"
                                                                label="Ghl Key"
                                                                disabled={!props.isAdmin}
                                                                validate={required('client key cannot be null.')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <TextInput
                                                                source="paymentMethods.ghl.merchantID"
                                                                label="Merchant Id"
                                                                disabled={!props.isAdmin}
                                                                validate={required('merchant id cannot be null.')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <TextInput
                                                                source="paymentMethods.ghl.terminalID"
                                                                label="Terminal Id"
                                                                disabled={!props.isAdmin}
                                                                validate={required('terminal id cannot be null.')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </>
                                        )}
                                        <div>
                                            <CardHeader title="Payment options" />
                                            <CardContent>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} className={classes.alignWithTitle}>
                                                        <FormLabel component="legend">Enable payment</FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormGroup className={classes.formGroup}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        name="iPay88"
                                                                        checked={paymentEnabled.includes("iPay88")}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                }
                                                                label="iPay88"
                                                                className={classes.formControlLabel}
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        name="ghl"
                                                                        checked={paymentEnabled.includes("ghl")}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                }
                                                                label="GHL"
                                                                className={classes.formControlLabel}
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        name="cashCoin"
                                                                        checked={paymentEnabled.includes("cashCoin")}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                }
                                                                label="Cash Coin"
                                                                className={classes.formControlLabel}
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        name="terminal"
                                                                        checked={paymentEnabled.includes("terminal")}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                }
                                                                label="Terminal"
                                                                className={classes.formControlLabel}
                                                            />
                                                        </FormGroup>

                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    </>
                                )}
                            </Grid>
                            <Divider />
                            <CardActions style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Button type="submit" variant="contained" color="primary">
                                    Save
                                </Button>
                                <Button type="submit" variant="contained" onClick={removeMachine}  style={{ backgroundColor: "#f44336", color: "#fff" }}>
                                    Remove
                                </Button>
                            </CardActions>
                        </SimpleForm>
                    ) : (
                        <p>Loading...</p>
                    )}
                </CardContent>
            </div>
        </>
    )
}

export default MachineSettings;
