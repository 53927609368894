import React from "react";

import ProgressBar from 'react-bootstrap/ProgressBar'

import "../components/css/ProgressBar.css";


const ProgressBarComponent = ({ progress, label, value }) => {

    const getVariant = () => {
        if(progress >= 70) {
            return "success";
        } else if(progress >= 40) {
            return "warning"
        } else {
            return "danger";
        }
    };

    const errorVariant = () => {
        if(progress >= 100) {
            return "success";
        } else if(progress >= 50) {
            return "warning"
        } else {
            return "danger";
        }
    };
    
    const variant = label === "Slot Error" ? errorVariant() : getVariant();
    
    return (
        <>
            <div className="progress-container">
                <span className="progress-label-start">{label}</span>
                <ProgressBar variant={variant} now={progress} className="custom-progress-bar" />
                <span className="progress-label-end">{value}</span>
            </div>
        </>
    )
};

export default ProgressBarComponent;