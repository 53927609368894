import React, { useState } from "react";
import { format, subDays, subMonths, subYears } from "date-fns";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import { FaChevronDown, FaRegCalendarAlt } from "react-icons/fa";

import "react-date-range/dist/styles.css"; // Main CSS file
import "react-date-range/dist/theme/default.css";
import enUS from 'date-fns/locale/en-US';

const DateRangeDropdown = ({ selectedRange, setSelectedRange, dateRange, setDateRange, setFormattedDateRange }) => {
    
    const [customRange, setCustomRange] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });

    const ranges = [
        "Today",
        "Last 7 Days",
        "Last 30 Days",
        "Last 3 Months",
        "Last 6 Months",
        "Last 1 Year",
    ];

    function getDateRange(range) {
      const today = new Date();
      switch (range) {
          case "Today":
              return { display: `${format(today, "dd MMM")}`, return: `${format(today, "yyyy-MM-dd")}/${format(today, "yyyy-MM-dd")}` };
          case "Last 7 Days":
              return { display: `${format(subDays(today, 7), "dd MMM")} - ${format(today, "dd MMM")}`, return: `${format(subDays(today, 7), "yyyy-MM-dd")}/${format(today, "yyyy-MM-dd")}` };
          case "Last 30 Days":
              return { display: `${format(subMonths(today, 1), "dd MMM")} - ${format(today, "dd MMM")}`, return: `${format(subMonths(today, 1), "yyyy-MM-dd")}/${format(today, "yyyy-MM-dd")}` };
          case "Last 3 Months":
              return { display: `${format(subMonths(today, 3), "MMM yyyy")} - ${format(today, "MMM yyyy")}`, return: `${format(subMonths(today, 3), "yyyy-MM-dd")}/${format(today, "yyyy-MM-dd")}` };
          case "Last 6 Months":
              return { display: `${format(subMonths(today, 6), "MMM yyyy")} - ${format(today, "MMM yyyy")}`, return: `${format(subMonths(today, 6), "yyyy-MM-dd")}/${format(today, "yyyy-MM-dd")}` };
          case "Last 1 Year":
              return { display: `${format(subYears(today, 1), "yyyy")} - ${format(today, "yyyy")}`, return: `${format(subYears(today, 1), "yyyy-MM-dd")}/${format(today, "yyyy-MM-dd")}` };
          default:
              return { display: "", return: "" };
      }
    };

    const handleSelect = range => {
      setSelectedRange(range);
      if (range !== "Custom") {
        const { display, return: formatted } = getDateRange(range);
        setDateRange(display);
        setFormattedDateRange(formatted);
      }
    };

    const handleCustomRangeChange = (ranges) => {
      const { selection } = ranges;
      setCustomRange(selection);
      setSelectedRange("Custom");
      const formattedStart = format(selection.startDate, "yyy-MM-dd");
      const formattedEnd = format(selection.endDate, "yyy-MM-dd");
      setDateRange(`${format(selection.startDate, "dd MMM yyyy")} - ${format(selection.endDate, "dd MMM yyyy")}`);
      setFormattedDateRange(`${formattedStart}/${formattedEnd}`);
    };

    return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
              <DropdownButton
                  variant="primary"
                  id="dropdown-basic"
                  className="custom-dropdown"
                  title={
                    <div style={{
                      display: "flex", 
                      alignItems: "center", 
                      justifyContent: "space-between", 
                      width: "100%", 
                      padding: "5px 10px" 
                    }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                        <FaRegCalendarAlt size={14} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "0px 5px" }}>
                            <div style={{ fontSize: "12px" }}>{selectedRange}</div>
                            <div style={{ fontSize: "10px", marginTop: "2px" }}>{dateRange}</div>
                        </div>
                      </div>
                      <FaChevronDown size={14} />
                    </div>
                  }
              >
                <div style={{ display: "flex", flexDirection: "row", padding: "10px" }}>
                  <div
                      style={{
                          borderRight: "1px solid #ccc",
                          paddingRight: "10px",
                          marginRight: "10px",
                      }}
                  >
                      <DateRangePicker
                          ranges={[customRange]}
                          onChange={handleCustomRangeChange}
                          rangeColors={["#007bff"]}
                          locale={enUS}
                          showDateDisplay={false} // Hide extra date display
                      />
                  </div>
                  <div style={{ flex: "1" }}>
                      {ranges.map((range) => (
                          <Dropdown.Item key={range} onClick={() => handleSelect(range)}>
                              {range}
                          </Dropdown.Item>
                      ))}
                  </div>
                </div>
              </DropdownButton>
          </div>
        </>
    )
};

export default DateRangeDropdown;