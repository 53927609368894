import React, { useEffect, useState } from 'react'
import { useGetSlotsRealtime } from './RefreshMachineStatus';
import { colors, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from 'react-admin';
import SlotGridBoard from './SlotGridBoard';
import Doc2UsLockerGrid from './Doc2UsLockerGrid';
import LockerGrid from './LockerGrid';

const useStyles = makeStyles(theme => ({
    boardTabs: {
      marginTop: theme.spacing(3),
  
      '& .Mui-selected': {
        backgroundColor: 'white',
      },
  
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    divider: {
      backgroundColor: colors.grey[300],
    },
    content: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginRight: theme.spacing(2),
      border: '1px solid',
      borderRadius: 30,
    },
    spacingTop: {
      marginTop: theme.spacing(1),
      backgroundColor: colors.grey[300],
    },
}));

const MachineDispenseMode = {
    vechnology: 1, //1
    doc2us: 2, //2
};

const BoardTabs = ({ machineId, machineModel, boardData, clientType }) => {
    const classes = useStyles();
    const [tab, setTab] = useState(null);
    const getSlotState = useGetSlotsRealtime(machineId);
    const handleTabsChange = (event, value) => setTab(value);
    const boardButtons = [];

    useEffect(() => {
        if(boardData.loaded == true && boardData.boards.length > 0) {
            setTab(boardData.boards[0].type === 'spiral' ? 'slot1' : 'locker1');
        }
    }, [boardData]);

    const InitializeTabContainer = tab => {
        if (tab === null) {
          return <LoadingIndicator colors="#00000" />;
        }
    
        return (
          <Tabs
            value={tab}
            onChange={handleTabsChange}
            indicatorColor="secondary"
            className={classes.boardTabs}
            variant="scrollable"
            scrollButtons="auto"
          >
            {InitializeTabs(boardData.boards)}
          </Tabs>
        );
    };

    const InitializeTabs = (boardIdList) => {
        let lockerIndex = 0;
        let slotIndex = 0;

        if(boardIdList == null) {
            return null;
        }

        boardIdList.map(board => {
            if(board.type == "spiral") {
                slotIndex++;
                boardButtons.push({
                    value: `slot${slotIndex}`,
                    label: board.name,
                    type: board.type,
                    boardID: board.id,
                    boardData: board,
                });
            } else {
                lockerIndex++;
                boardButtons.push({
                    value: `locker${lockerIndex}`,
                    label: board.name,
                    type: board.type,
                    boardID: board.id,
                    boardData: board,
                });
            }
        });

        return boardButtons.map(board => {
            return <Tab key={board.value} label={board.label} value={board.value} className={classes.buttons} />
        })
    };

    const InitializeContents = (masterSchemaData) => {
        if(masterSchemaData == null) {
            return null;
        };

        return boardButtons.map(board => {
            if(board.boardData.type == "spiral") {
                return (
                    tab === board.value && (
                        <SlotGridBoard
                          key={board.value}
                          machineId={machineId}
                          machineModel={machineModel}
                          boardData={board.boardData}
                        />
                    )
                );
            } else {
                //comment out this function first as confimed by Steven
                // if (MachineDispenseMode.doc2us === clientType) {
                //     return (
                //         tab === board.value && (
                //             <Doc2UsLockerGrid
                //                 key={board.value}
                //                 machineId={machineId}
                //                 state={getSlotState}
                //                 machineModel={machineModel}
                //                 boardData={board.boardData}
                //             />
                //         )
                //     );
                // }
                return (
                    tab === board.value && (
                        <LockerGrid
                            key={board.value}
                            machineId={machineId}
                            state={getSlotState}
                            machineModel={machineModel}
                            boardData={board.boardData}
                        />
                    )
                );
            }
        })
    };

    return (
        <>
            <div style={{ width: '90%' }}>
                {InitializeTabContainer(tab)}
                <div className={classes.content}>{InitializeContents(boardData.boards)}</div>
            </div>
        </>
    );
}

export default BoardTabs;