import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { ReferenceInput, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import { Button, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { doc, setDoc } from 'firebase/firestore';
import _extendLayout from '../../layout/_extendLayout';

const CategoryEdit = () => {
    const { id } = useParams();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState("");
    const [catDetails, setCatDetails] = useState(null);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
                await getCatById();
            }
        })

        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            setClientId(res.data?.client?.id);
        } catch (err) {
            console.log(err);
        }
    };

    const getCatById = async () => {
        try {
            const res = await dataProvider.getOne("categories", { id });
            setCatDetails(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    if(!catDetails) {
        return <_extendLayout><p>Loading...</p></_extendLayout>
    };

    const handleSave = async (d) => {
        let obj = {
            client: d.client,
            name: d.name
        }

        try{
            const docRef = doc(db, "categories", id);

            await setDoc(docRef, obj, { merge: true });

            alert("Category updated successfully!");
            navigate("/categories")
        } catch (err) {
            console.log("Error saving to Firebase:", err);
            alert("Failed to save product. Please try again.")
        }
    };

    return (
        <>
            <_extendLayout>
                <div className="listMachines">
                    <CardHeader title="Edit Catefory" />
                    <CardContent>
                        <p>Category Details</p>
                        <SimpleForm toolbar={null} onSubmit={handleSave}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextInput label="Name" source="name" fullWidth validate={required("Please enter a name")} defaultValue={catDetails.name ?? ""} />
                                </Grid>
                                {isAdmin ? (
                                    <Grid item xs={12}>
                                        <ReferenceInput source="client" reference="clients" fullWidth perPage={99999} filterToQuery={() => {}}>
                                            <SelectInput
                                                optionText="companyName"
                                                variant="outlined"
                                                fullWidth
                                                margin="none"
                                                perPage={99999}
                                                validate={required("Please select a client")}
                                                defaultValue={catDetails.client}
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                ) : (
                                    <input type="hidden" value={clientId} />
                                )}
                            </Grid>
                            <CardActions>
                                <Button type="submit" variant="contained" color="primary">
                                    Save
                                </Button>
                            </CardActions>
                        </SimpleForm>
                    </CardContent>
                </div>
            </_extendLayout>
        </>
    );
}

export default CategoryEdit;
