import React from "react";
import { format } from "date-fns"; 
import Table from "react-bootstrap/Table";

import "../components/css/LatestTrans.css";

const LatestTrans = ({ latestTransactions }) => {
    
    const formatTransactionDate = (isoString) => {
        const date = new Date(isoString);
        return format(date, "HH:mm dd/MM");
    };

    return(
        <>
            <h6 className="lblLatestTrans">Latest Transactions by All Machine</h6>
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr>
                        <th>Machine</th>
                        <th>Date</th>
                        <th>Item</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {latestTransactions?.length > 0 ? (
                        latestTransactions.map((i) => (
                            <tr key={i.transactionId}>
                                <td className="fontHandle">{i.machineUUID}</td>
                                <td className="fontHandle">{formatTransactionDate(i.transactionDate)}</td>
                                <td className="fontHandle">{i.productName}</td>
                                <td className="fontHandle">{i.amount.replace("RM", "").trim()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" style={{ textAlign: "center", padding: "10px", fontStyle: "italic" }}>
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

export default LatestTrans;