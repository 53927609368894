import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ImageField, NumberInput, required, useDataProvider } from 'react-admin';
import { Box, Grid, Typography, InputAdornment } from '@material-ui/core';
import MROAccessGroupInput from './MROAccessGroupInput';

const useStyles = makeStyles(theme => {
    return {
      root: {
        marginTop: theme.spacing(3),
      },
      label: {
        marginRight: theme.spacing(0.5),
      },
    };
});

const ProductForm = ({ productId, formValues, dirtyFields, modified, machineMode, clientId, onMROAccessChange, onProductRecordChange }) => {
    const [productRecord, setProductRecord] = useState(null);
    const [MROAccess, setMROAccess] = useState(null);
    const dataProvider = useDataProvider();
    const classes = useStyles();
    
    useEffect(() => {
        if(!productId) return null;
        getProductDetails();
    }, [productId]);


    const getProductDetails = async () => {
        try {
            const res = await dataProvider.getOne("products", { id: productId });
            setProductRecord(res.data);
            if(onProductRecordChange) {
                onProductRecordChange(res.data);
            }
        } catch(err) {
            console.error("Error fetching product:", err);
        }
    };

    const InlineField = ({ label, source }) => {
        return(
            <Grid display="flex" alignItems="flex-start" spacing={1} container>
                <Grid className={classes.label} style={{ marginRight: '0.5rem', fontWeight: 'bold', whiteSpace: 'nowrap', marginTop: '0.2rem' }}>
                    <Typography component="span">{label}:</Typography>
                </Grid>
                <Grid item xs>
                    <Typography style={{ whiteSpace: 'wrap' }}>{typeof source === "function" ? source() : <Typography component="span">{productRecord?.[source] || "N/A"}</Typography>}</Typography>
                </Grid>
            </Grid>
        );
    };

    if (!productRecord) return <Typography>Loading product details...</Typography>;

    const initialPriceCash = dirtyFields?.product?.id
        ? productRecord?.priceCash
        : formValues?.product?.priceCash ?? productRecord?.priceCash;

    const initialPriceCredit = modified?.product?.id
        ? productRecord?.priceCredit
        : formValues?.product?.priceCredit ?? productRecord?.priceCredit;

    const initialMROAccessGroup = modified?.product?.id
        ? productRecord?.mroAccessGroup ?? null
        : formValues?.product?.mroAccessGroup ?? productRecord?.mroAccessGroup ?? null;

    const price = msg => (value) => {
        const regex = /^\d*(.\d{0,2})?$/;
        if (!regex.test(value)) {
            return msg || 'Invalid';
        }
    };
    
    return (
        <>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} md={4}>
                    <ImageField record={productRecord} source="image.src" />
                </Grid>
                <Grid container spacing={3} xs={12} md={8}>
                    <Grid item xs={12}>
                        <InlineField label="Product Name" source="name" />
                        <InlineField label="Description" source="description" />
                        <InlineField label="SKU" source="sku" />
                        <InlineField label="Serial Number" source="serialNumber" />
                        <InlineField 
                            label="Price"
                            source={() => 
                                productRecord?.priceCash
                                    ? productRecord.priceCash.toLocaleString("en-MY", {
                                        style: "currency",
                                        currency: "MYR"
                                    })
                                    : "N/A"
                            }
                        />
                        {machineMode === "mro" && <InlineField label="Price (Credit)" source="priceCredit" />}
                    </Grid>
                    {machineMode === "mro" ? (
                        <>
                            <Grid item xs={12}>
                                <NumberInput
                                    label="Price (Credit)"
                                    source="priceCredit"
                                    fullWidth
                                    validate={[
                                        required("Please enter a credit price"),
                                        price("Please enter a valid credit price with no more than two decimals.")
                                    ]}
                                    defaultValue={initialPriceCredit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MROAccessGroupInput 
                                    name="product.mroAccessGroup" 
                                    client={clientId} 
                                    initialValue={productRecord.mroAccessGroup || []} 
                                    value={formValues?.product?.mroAccessGroup || []} 
                                    onValueChange={(updatedValues) => {
                                        onMROAccessChange(updatedValues);
                                    }}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <input type="hidden" name="product.priceCredit" value={initialPriceCredit || ""} />
                            <input type="hidden" name="product.mroAccessGroup" value={initialMROAccessGroup || ""} />
                        </>
                    )}
                    {machineMode === "commercial" ? (
                        <Grid item xs={12}>
                            <NumberInput
                                label="Price"
                                source="product.priceCash"
                                fullWidth
                                validate={[
                                    required("Please enter a price"),
                                    price("Please enter a valid price with no more than two decimals.")
                                ]}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}
                                defaultValue={initialPriceCash}
                            />
                        </Grid>
                    ) : (
                        <>
                            <input type="hidden" name="product.priceCash" value={initialPriceCash} />
                        </>
                    )}
                    {productRecord && (
                        <>
                            <input type="hidden" name="product.name" value={productRecord.name} />
                            <input type="hidden" name="product.description" value={productRecord.description} />
                            <input type="hidden" name="product.categoryId" value={productRecord.category} />
                            <input type="hidden" name="product.limitationRule" value={productRecord.limitationRule} />
                            <input type="hidden" name="product.image.src" value={productRecord?.image?.src ?? ""} />
                            <input type="hidden" name="product.infographic.src" value={productRecord?.infographic?.src ?? ""} />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default ProductForm;
