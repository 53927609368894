import React from "react";
import { Table } from "react-bootstrap";

import "../components/css/LatestTrans.css";

const MachinePerTable = ({ machineRanking, isLoading }) => {
    
    return(
        <>
            <div className="macTabDiv">
                <h6 className="lblMachineTable">Machine Ranking</h6>

                <Table striped bordered hover responsive size="sm">
                    <thead>
                        <tr>
                            <th>Machine UUID</th>
                            <th>Frequency</th>
                            <th>Sales</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="3" style={{ textAlign: "center", padding: "10px", fontStyle: "italic", fontWeight: "bold" }}>
                                    Loading...
                                </td>
                            </tr>
                        ) : machineRanking?.length > 0 ? (
                            machineRanking.map((items) => (
                                <tr key={items.machineId}>
                                    <td className="fontHandle">{items.machineUUID}</td>
                                    <td className="fontHandle">{items.frequency}</td>
                                    <td className="fontHandle">{items.totalSales?.replace("RM", "").trim()}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" style={{ textAlign: "center", padding: "10px", fontStyle: "italic" }}>
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default MachinePerTable;