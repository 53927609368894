import React, { useEffect, useState } from "react";
import OverallStatus from "./OverallStatus";
import MachineHealth from "./MachinesHealth";
import LatestTrans from "./LatestTrans";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "../components/css/RealtimeStatus.css";

const RealtimeStatus = () => {

    const auth = getAuth();
    const navigate = useNavigate();

    const [onlineData, setOnlineData] = useState({ onlinePercentage: 0, onlineVal: "" });
    const [stockData, setStockData] = useState({ stockPercentage: 0, stockVal: "" });
    const [errorData, setErrorData] = useState({ errorPercentage: 0, errorVal: "" });
    const [machinesDetails, setMachinesDetails] = useState([]);
    const [latestTransactions, setLatestTransaction] = useState([]);

    //get machines status and latest transaction
    const getMachineStatusAndTrans = async () => {
        try {
            let token =  await waitForToken();

            if(!token) {
                throw new Error("No token found in local storage");
            }

            const res = await axios.get(`https://api-dev-v2.vechnology.com.my/api/machine_transaction/machinesStatus`, {
                params: {
                    status: 1, //success
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            return res.data?.result?.data ?? { onlinePercentage: 0, stock: 0, health: 0, machinesDetail: [] };
        } catch (err) {
            console.log('Error fetching data:', err);
            return { onlinePercentage: 0, stock: 0, health: 0, machinesDetail: [] };
        }
    };

    //get latest transaction with desc order
    const getLatestTrans = async () => {
        try {
            let token =  await waitForToken();

            const res = await axios.get(`https://api-dev-v2.vechnology.com.my/api/machine_transaction/latestTrans`, {
                params: {
                    status: 1, //success
                    limit: 5, //select top 5
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            return res.data.result.data;
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const waitForToken = async (timeout = 5000) => {
        return new Promise((resolve, reject) => {
            let timeElapsed = 0;
            const interval = 200; // Check every 200ms
            
            const checkToken = setInterval(() => {
                const token = localStorage.getItem("token");
                if (token) {
                    clearInterval(checkToken);
                    resolve(token);
                }
                timeElapsed += interval;
                if (timeElapsed >= timeout) {
                    clearInterval(checkToken);
                    reject("Token not found within timeout!");
                }
            }, interval);
        });
    };

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                const latestTransaction = await getLatestTrans();
                const datas = await getMachineStatusAndTrans();

                const online = {
                    onlinePercentage: datas.onlinePercentage ?? 0,
                    onlineVal: datas.onlineMachines !== undefined && datas.totalMachines !== undefined 
                        ? `${datas.onlineMachines}/${datas.totalMachines}`
                        : "0/0"
                };
                

                const health = {
                    errorPercentage: (100 - datas.health) ?? 0,
                    errorVal: datas.slotError !== undefined && datas.totalSlot !== undefined 
                        ? `${datas.slotError}/${datas.totalSlot}`
                        : "0/0"
                };

                const stock = {
                    stockPercentage: datas.stock,
                    stockVal: `${datas.stock}%`,
                };
                
                setOnlineData(online);
                setStockData(stock);
                setErrorData(health);
                setMachinesDetails(datas.machinesDetail);
                setLatestTransaction(latestTransaction);
            } else {
                navigate("/");
            }
        });

        return () => checkAuth();
    }, []);

    return(
        <>
            <div className="realtimeArea">
                <h4 className="lblRealtime">Realtime Status</h4>
                <div className="overallDiv">
                    <OverallStatus onlineData={onlineData} stockData={stockData} errorData={errorData} />
                </div>
                <div className="machineHealthDiv">
                    <MachineHealth machinesDetails={machinesDetails} />
                </div>
                <div className="latest-trans-div">
                    <LatestTrans latestTransactions={latestTransactions} />
                </div>
            </div>
        </>
    );
};

export default RealtimeStatus;