import React from "react";
import logo from "../assets/img/ve-cloud.png";
import { FaGem, FaHeart, FaList, FaBars, FaHome, FaRegEnvelope, FaCalendarAlt, FaTrello, FaRegUser, FaRegFileAlt, FaRegImages, FaRegCheckSquare, FaBloggerB, FaFileInvoice, FaRegFileCode, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu,  } from "react-pro-sidebar";

import './css/SideBar.css';

const SideBar = ({  handleMouseLeave, handleMouseEnter, toggled, setToggled, setBroken, collapsed, userAccess, isAdmin }) => {
    
    return(
        <>
            <div onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()} style={{ display: "flex", height: "100%", flexDirection: "column", }}>
                <Sidebar 
                    collapsed={collapsed}
                    toggled={toggled}
                    onBackdropClick={() => setToggled(false)}
                    onBreakPoint={setBroken}
                    breakPoint="md"
                    className="sidebar-container" 
                    style={{ zIndex:"9999", backgroundColor:"#f8f9fa" }} 
                >
                    <div className="sidebar-header">
                        <img src={logo} alt="logo" className="ve-logo" />
                        {!collapsed && <span>VECLOUD</span>}
                    </div>

                    {/* If user is admin, show all menu items */}
                    {isAdmin ? (
                        <Menu iconShape="circle">
                            <MenuItem icon={<FaGem />} component={<Link to="/dashboard" className="link-underline" />}>
                                Dashboard
                            </MenuItem>
                            <MenuItem icon={<FaRegFileCode />} component={<Link to="/transactions" className="link-underline" />}>
                                Sales Report
                            </MenuItem>
                            <SubMenu label="MRO Report" icon={<FaRegFileAlt />}>
                                <MenuItem component={<Link to="/mro-transactions" />}>By Transaction</MenuItem>
                                <MenuItem component={<Link to="/mro-transactions-by-product" />}>By Product</MenuItem>
                                <MenuItem component={<Link to="/mro-transactions-by-employee" />}>By Employee</MenuItem>
                            </SubMenu>
                            <SubMenu label="Inventory" icon={<FaBars />}>
                                <MenuItem component={<Link to="/products" />}>Product</MenuItem>
                                <MenuItem component={<Link to="/categories" />}>Category</MenuItem>
                            </SubMenu>
                            <SubMenu label="Vending Machine" icon={<FaRegImages />}>
                                <MenuItem component={<Link to="/machines" />}>Status</MenuItem>
                            </SubMenu>
                            <MenuItem icon={<FaRegUser />} component={<Link to="/users" className="link-underline" />}>
                                Users
                            </MenuItem>
                            <SubMenu label="MRO" icon={<FaCalendarAlt />}>
                                <MenuItem component={<Link to="/employees" />}>Employee</MenuItem>
                                <MenuItem component={<Link to="/employeeTypes" />}>Type</MenuItem>
                                <MenuItem component={<Link to="/employeeTopup" />}>Topup</MenuItem>
                            </SubMenu>
                            <MenuItem icon={<FaTrello />} component={<Link to="/notifications" className="link-underline" />}>
                                Notifications
                            </MenuItem>
                        </Menu>
                    ) : (
                        <Menu iconShape="circle">
                            <MenuItem icon={<FaGem />} component={<Link to="/dashboard" className="link-underline" />}>
                                Dashboard
                            </MenuItem>
                            {userAccess.includes("report") && (
                                <MenuItem icon={<FaRegFileCode />} component={<Link to="/transactions" className="link-underline" />}>
                                    Sales Report
                                </MenuItem>
                            )}
                            {userAccess.includes("mro_report") && (
                                <SubMenu label="MRO Report" icon={<FaRegFileAlt />}>
                                    <MenuItem component={<Link to="/mro-transactions" />}>By Transaction</MenuItem>
                                    <MenuItem component={<Link to="/mro-transactions-by-product" />}>By Product</MenuItem>
                                    <MenuItem component={<Link to="/mro-transactions-by-employee" />}>By Employee</MenuItem>
                                </SubMenu>
                            )}
                            {userAccess.includes("inventory") && (
                                <SubMenu label="Inventory" icon={<FaBars />}>
                                    <MenuItem component={<Link to="/products" />}>Product</MenuItem>
                                    <MenuItem component={<Link to="/categories" />}>Category</MenuItem>
                                </SubMenu>
                            )}
                            {userAccess.includes("vending_machine") && (
                                <SubMenu label="Vending Machine" icon={<FaRegImages />}>
                                    <MenuItem component={<Link to="/machines" />}>Status</MenuItem>
                                </SubMenu>
                            )}
                            {userAccess.includes("user") && (
                                <MenuItem icon={<FaRegUser />} component={<Link to="/users" className="link-underline" />}>
                                    Users
                                </MenuItem>
                            )}
                            {userAccess.includes("mro") && (
                                <SubMenu label="MRO" icon={<FaCalendarAlt />}>
                                    <MenuItem component={<Link to="/employees" />}>Employee</MenuItem>
                                    <MenuItem component={<Link to="/employeeTypes" />}>Type</MenuItem>
                                    <MenuItem component={<Link to="/employeeTopup" />}>Topup</MenuItem>
                                </SubMenu>
                            )}
                            <MenuItem icon={<FaTrello />} component={<Link to="/notifications" className="link-underline" />}>
                                Notifications
                            </MenuItem>
                        </Menu>
                    )}                    
                </Sidebar>
            </div>
        </>
    )
}

export default SideBar;