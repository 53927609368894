import React from 'react';
import { Box, CardContent, Typography, Card } from '@material-ui/core';
import SlotItem from './SlotItem';
import "../../components/css/SlotGrid.css";

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const SlotGrid = ({ state, machineId, machineModel, numOfRows, numOfColumns }) => {
    const { data, loaded } = state;

    const isCompatMachine = machineModel === "VE-E1208N" || machineModel === 'VE-E1208C';

    let col_num = 12;
    let row_num = 6;

    col_num = typeof numOfColumns === "number" && !isNaN(numOfColumns) ? numOfColumns : 12;
    row_num = typeof numOfRows === "number" && !isNaN(numOfRows) ? numOfRows : 6;

    if(!loaded) return null;

    const Row = ({ rowIndex }) => {
        const rowNumber = ALPHABET[rowIndex];
        return (
            <div>
                <Typography className="row-label">Row {rowNumber}</Typography>
                <Box className="row-slot">
                    {Array.from({ length: col_num }).map((_, i) => {
                        const slotIndex = rowIndex * col_num + i;
                        const slotNumber = slotIndex + 1;
                        const itemData = data[slotNumber] || {};
                        
                        if(i >= 10) return null; // For some reason, we want to hide column 11 and 12 and display only 1-10
                        
                        return (
                            <SlotItem
                                key={`slot-${rowIndex}-${i}`}
                                data={itemData}
                                rowNumber={rowNumber + 1}
                                colNumber={`${rowNumber}${i + 1}`}
                                slotNumber={slotNumber}
                                machineId={machineId}
                                disable={false}
                                is12Slots={false}
                            />
                        );
                        
                    })}
                </Box>
            </div>
        );
    };

    const CompatRow = ({ rowIndex }) => {
        const rowNumber = ALPHABET[rowIndex];
        return (
            <div>
                <Typography className="row-label">Row {rowNumber}</Typography>
                <Box className="row-slot">
                    {Array.from({ length: col_num }).map((_, i) => {
                        const slotIndex = rowIndex * col_num + i;
                        const slotNumber = slotIndex + 1;
                        const itemData = data[slotNumber] || {};
                        
                        if(i >= 12) return null;
                        
                        if(rowIndex > 2 && rowIndex < 6 && i > 7 && i < 13) {
                            return (
                                <SlotItem
                                    key={`compat-slot-${rowIndex}-${i}`}
                                    data={itemData}
                                    rowNumber={rowNumber}
                                    colNumber={`${rowNumber}${i + 1}`}
                                    slotNumber={slotNumber}
                                    machineId={machineId}
                                    disable={true}
                                    is12Slots={true}
                                />
                            );
                        } else {
                            return (
                                <SlotItem
                                    key={`compat-slot-${rowIndex}-${i}`}
                                    data={itemData}
                                    rowNumber={rowNumber}
                                    colNumber={`${rowNumber}${i + 1 }`}
                                    slotNumber={slotNumber}
                                    machineId={machineId}
                                    disable={false}
                                    is12Slots={true}
                                />
                            );
                        }
                        
                    })}
                </Box>
            </div>
        );
    };

    return (
        <>
            <div style={{ width: '90%' }}>
                <div className="label">
                    <Box className="slot-status">Legends:</Box>
                    <Box className="occupied">Occupied</Box>
                    <Box className="error">Locked</Box>
                    <Box className="ofs">Out of Stock</Box>
                    <Box className="empty">Empty Slot</Box>
                </div>
                <Card className="card">
                    <CardContent>
                        {Array.from({ length: row_num }, (_, i) => (
                            isCompatMachine 
                                ? <CompatRow rowIndex={i} key={`compat-row-${i}`} /> 
                                : <Row rowIndex={i} key={`row-${i}`} />
                        ))}
                    </CardContent>
                </Card>
            </div>
        </>
    )
}

export default SlotGrid;