import { Button, CardContent, CardHeader, Grid } from '@material-ui/core';
import { auth, db } from '../../firebase';
import React, { useEffect, useState } from 'react'
import { TextInput, useDataProvider, required, SimpleForm, minValue, ReferenceInput, SelectInput, useNotify } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { updateDoc, doc } from 'firebase/firestore';
import _extendLayout from '../../layout/_extendLayout';

const EmployeeTypeEdit = () => {
    const { id } = useParams();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();

    const [employeeTypeData, setEmployeeTypeData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState(false);

    useEffect(() => {

        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await fetchEmployeeType(id);
                await fetchCurrentUser(user.uid);
            }
        })

        return () => checkAuth();
    }, [id])

    const fetchEmployeeType = async (id) => {
        try{ 
            const res = await dataProvider.getOne("employeeTypes", {id});
            setEmployeeTypeData(res.data);
        } catch (err) {
            console.log("error: ", err);
        }
    };

    const fetchCurrentUser = async (userId) => {
        try{
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            if(res.data.role == "client") {
                setClientId(res.data?.client?.id);
            }
        } catch(err) {
            console.log("error: ", err);
        }
    };

    const handleSave = async (data) => {
        try {
            if(typeof id !== "string" || id.trim() === "") {
                throw new Error("Invalid or missing document ID");
            }
            const employTypeRef = doc(db, "employeeTypes", id);
            
            await updateDoc(employTypeRef, {
                name: data.name,
                credit: data.credit,
                client: (data.client == undefined) ? clientId : data.client,
            });

            notify("Updated user successfully");
            navigate("/employeeTypes");
        } catch(err) {
            console.log(err);
        }
    };

    return (
        <>
            <_extendLayout>
                <div className='listMachines'>
                    {employeeTypeData ? (
                        <CardContent>
                            <SimpleForm toolbar={null} onSubmit={handleSave}>
                                <CardHeader title="Edit User" />
                                <p>Type Details</p>
                                <Grid container spacing={3}>
                                    <Grid item mb={2} xs={12} sm={12} md={6}>
                                        <TextInput
                                            source="name" 
                                            label="Employee Type"
                                            fullWidth
                                            defaultValue={employeeTypeData.name}
                                            validate={required("Please enter a employee type.")}
                                        />
                                    </Grid>
                                    <Grid item mb={2} xs={12} sm={12} md={6}>
                                        <TextInput
                                            type="number"
                                            source="credit" 
                                            label="Credit"
                                            fullWidth
                                            defaultValue={employeeTypeData.credit}
                                            validate={[required("Please enter a credit."), minValue(0, "Credit cannot less than 0")]}
                                        />
                                    </Grid>
                                    {isAdmin ? (
                                        <Grid item mb={2} xs={12} sm={12} md={6}>
                                            <ReferenceInput source="client" reference="clients" filterToQuery={() => {}}>
                                                <SelectInput
                                                    optionText="companyName"
                                                    variant="outlined"
                                                    fullWidth
                                                    validate={required("Please select a client")}
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                    ) : (
                                        <input type="hidden" value={employeeTypeData.client} />
                                    )}
                                </Grid>
                                <Button type="submit" variant="contained" color="primary">
                                    Save
                                </Button>
                            </SimpleForm>
                        </CardContent>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </_extendLayout>
        </>
    )
};

export default EmployeeTypeEdit;
