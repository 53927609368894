import { CardHeader } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { CreateButton, Datagrid, ExportButton, ImageField, List, NumberField, ReferenceField, TextField, TopToolbar, useDataProvider } from 'react-admin';
import { useClientHas } from "../Machines/RefreshMachineStatus";
import SearchInput from '../SearchInput';
import _extendLayout from '../../layout/_extendLayout';
import ProActionMenu from './ProActionMenu';

const exporter = async (datas) => {
    const headers = [
        "ID",
        "Category",
        "Client",
        "Created At",
        "Limitation Rule",
        "MRO Access Group",
        "Name",
        "Price Cash",
        "Price Credit",
        "Updated At",
        "Description",
        "Image",
        "Serial Number",
        "SKU",
    ];

    let csvRows = [headers.join(",")];
    
    datas.forEach(e => {
        
        const formattedCreatedAt = e.createdAt?.toDate
            ? `"${e.createdAt.toDate().toLocaleString()}"`
            : e.createdAt?.seconds
                ? `"${new Date(e.createdAt.seconds * 1000).toLocaleString()}"`
                : '""';

        const formattedUpdatedAt = e.updatedAt?.toDate
            ? `"${e.updatedAt.toDate().toLocaleString()}"`
            : e.updatedAt?.seconds
                ? `"${new Date(e.updatedAt.seconds * 1000).toLocaleString()}"`
                : '""';

        const commonDetails = [
            e.id,
            e.category ?? "",
            e.client ?? "",
            formattedCreatedAt,
            e.limitationRule ?? "",
            `"${e.mroAccessGroup?.join(", ") ?? ""}"`,
            e.name ?? "",
            e.priceCash ?? "",
            e.priceCredit ?? "",
            formattedUpdatedAt,
            e.description ?? "",
            e.image?.src ?? "",
            e.serialNumber ?? "",
            e.sku ?? "",
        ];
        csvRows.push([...commonDetails].join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "products.csv";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const CustomActions = ({ searchInput, ...props }) => (
    <TopToolbar style={{ width: "100%", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
            {searchInput}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
            <CreateButton label="Create" />
            <ExportButton label="Export" exporter={exporter} />
        </div>
    </TopToolbar>
);

const AdminList = ({data, ...props}) => (
    <Datagrid data={data} {...props} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
        <ImageField source="image.src" label="Image" />
        <TextField source="name" label="Name" />
        <ReferenceField label="Company" source="client" reference="clients" link={false}>
            <TextField source="companyName" />
        </ReferenceField>
        <TextField source="description" label="Description" />
        <TextField source="sku" label="SKU" />
        <NumberField
            source="priceCash"
            label="Price"
            locales="en-MY"
            options={{ style: "currency", currency: "MYR" }}
        />
        <NumberField source="priceCredit" label="Price (Credit)" />
        <ProActionMenu label="Actions" />
    </Datagrid>
);

const ClientList = ({ data, clientId, ...props }) => {
    const hasMRO = useClientHas(clientId, "mro");

    return (
        <Datagrid data={data} {...props} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
            <ImageField source="image.src" label="Image" />
            <TextField source="name" label="Name" />
            <TextField source="description" label="Description" />
            {hasMRO && <TextField source="sku" label="SKU" />}
            <NumberField
                source="priceCash"
                label="Price"
                locales="en-MY"
                options={{ style: "currency", currency: "MYR" }}
            />
            {hasMRO && <NumberField source="priceCredit" label="Price (Credit)" />}
            <ProActionMenu label="Actions" />
        </Datagrid>
    );
};

const ProductList = (props) => {

    const dataProvider = useDataProvider();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [proDetails, setProDetails] = useState(null);
    const [filteredData, setFilteredData] = useState(proDetails || []);

    const hanldeFilteredData = (filteredData) => {
        setFilteredData(filteredData)
    };

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
            }
        });
        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            setClientId(res.data?.client?.id)
        } catch (err) {
            console.log("User not found ", err);
        }
    };

    useEffect(() => {
        if(clientId !== null) {
            getProductDetails();
        }
    }, [clientId, isAdmin]);

    const getProductDetails = async () => {
        try {
            let res;
            if(isAdmin) {
                res = await dataProvider.getList("products", {
                    sort: { field: "createdAt", order: "DESC" },
                    pagination: { page: 1, perPage: 1000 },
                });
            } else {
                res = await dataProvider.getList("products", {
                    filter: { "client": clientId },
                    sort: { field: "createdAt", order: "DESC" },
                    pagination: { page: 1, perPage: 1000 },
                });
            }
            setProDetails(res.data)
            setFilteredData(res.data);
        } catch (err) {
            console.log("Error on fetching products", err);
        }
    };


    return (
        <>
            <_extendLayout>
                <div style={{ width: '90%' }}>
                    <CardHeader title="List Of Product" />
                    <List
                        {...props}
                        resource="products"
                        actions={<CustomActions searchInput={<SearchInput searchKeys={["name"]} data={proDetails} onFilteredData={hanldeFilteredData} />} />}
                        filter={clientId ? { "client": clientId } : { "client": "abc" }}
                        perPage={25}
                    >
                        {/* {isAdmin ? <AdminList data={filteredData} /> : <ClientList clientId={clientId} data={filteredData} /> } */}
                        {clientId ? <ClientList clientId={clientId} data={filteredData} /> : <AdminList data={filteredData} /> }
                    </List>
                </div>
            </_extendLayout>
        </>
    )
}

export default ProductList;
