import { CardHeader } from '@material-ui/core';
import React, { useState } from 'react'
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
import SearchInput from '../SearchInput';
import { StatusField } from './MachinesStatus';
import MacActionMenu from './MacActionMenu';

const MachinesList = ({ macList, actions, clientId, isAdmin, ...props }) => {
    const [filteredData, setFilteredData] = useState(macList || []);
    
    const hanldeFilteredData = (filteredData) => {
        setFilteredData(filteredData)
    };

    const searchFilters = <SearchInput searchKeys={["machineUUID", "location", "versionName"]} data={macList} onFilteredData={hanldeFilteredData} />;

    return (
        <>
            <div className="listMachines" style={{ width: "90%" }}>
                <CardHeader title="List Machines" />
                {macList && (
                    <>
                        <List
                            {...props}
                            resource="machines"
                            basePath="/machines"
                            actions={actions}
                            filter={{ "client.id": clientId }}
                            data={macList}
                            filters={searchFilters}
                            bulkActionButtons={false}
                            perPage={25}
                        >
                            <Datagrid data={filteredData} {...props} bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                                <TextField 
                                    source="machineUUID"
                                    label="UUID"
                                />
                                <TextField 
                                    source="location"
                                    label="Location"
                                />
                                {isAdmin && (
                                    <ReferenceField label="Company" source="client.id" reference="clients" link={false}>
                                        <TextField source="companyName" />
                                    </ReferenceField>
                                )}
                                <StatusField label="Status" />
                                <TextField
                                    source="versionName"
                                />
                                <MacActionMenu label="Actions" />
                            </Datagrid>
                        </List>
                    </>
                )}
            </div>
        </>
    )
}

export default MachinesList;
