import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import { CiBellOn } from "react-icons/ci";
import { Menu, MenuItem } from "react-pro-sidebar";
import { FaBars } from 'react-icons/fa';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/css/NavBar.css';
import { getAuth, signOut } from "firebase/auth";

const NavBar = ({ toggleNotificationList, broken, toggledMoibleSidebar, unReadCount, userDetails }) => {
    const [showDetails, setShowDetails] = useState(false);
    const detailsRef = useRef(null);

    const handleAvatarClick = (e) => {
        e.stopPropagation();
        setShowDetails(!showDetails);
    };
    
    const handleClickOutside = (event) => {
        if (detailsRef.current && !detailsRef.current.contains(event.target)) {
            setShowDetails(false);
        }
    };

    const logout = () => {
        //remove local storage
        localStorage.removeItem("token");
        localStorage.removeItem("userAccess");
        localStorage.removeItem("userDetails");

        const auth = getAuth();
        signOut(auth)
            .then(() => {
                window.location.href="/";
            })
            .catch((err) => {
                console.error("Error during logout: ", err);
            })
    };

    const getInitials = () => {
        if(userData?.firstName && userData?.lastName) {
            return `${userData.firstName.charAt(0).toUpperCase()}${userData.lastName.charAt(0).toUpperCase()}`;
        };
        return null;
    };

    const [userData, setUserData] = useState(() => {
        const storedUser = localStorage.getItem("userDetails");
        return storedUser ? JSON.parse(storedUser) : null;
    });

    useEffect(() => {
        if(userDetails) {
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            setUserData(userDetails);
        }
    }, [userDetails]);

    useEffect(() => {
        if (showDetails) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showDetails]);

    return (
        <>
            <Navbar bg="light" expand="lg">
                <div className="burger">
                    <div className="burger-icon">
                        {broken && (
                        <Menu iconShape="circle">
                            <MenuItem icon={<FaBars />} onClick={toggledMoibleSidebar}></MenuItem>
                        </Menu>
                            
                        )}
                    </div>
                    <div style={{ display: "flex", alignItems:"center", minHeight:'100%'}}>
                        <div className="notifications" onClick={toggleNotificationList}>
                            <div className="icon_wrap">
                                <CiBellOn size={40} />
                                {unReadCount > 0 && unReadCount <= 9 ? (
                                    <span className="notification-badge">{unReadCount}</span>
                                ) : unReadCount <= 0 ? (
                                    null
                                ) : (
                                    <span className="notification-badge">9+</span>
                                )}
                            </div>
                        </div>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', cursor: "pointer", fontSize: "16px" }} onClick={handleAvatarClick}>
                            {userData ? getInitials() : <AccountCircleIcon />}
                        </Avatar>
                        {/* User Details (Toggleable) */}
                        {showDetails && (
                            <div
                                ref={detailsRef}
                                className="profile_dd"
                            >
                                {/* Arrow */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '-10px',
                                        right: '15px',
                                        width: '0',
                                        height: '0',
                                        borderLeft: '10px solid transparent',
                                        borderRight: '10px solid transparent',
                                        borderBottom: '10px solid #f8f9fa',
                                        zIndex: 1001,
                                    }}
                                ></div>
                                <div style={{ textAlign: 'center', display: "flex", justifyContent: 'center' }}>
                                    <Avatar sx={{ bgcolor: 'secondary.main', width: 60, height: 60 }}>
                                        {userData ? getInitials() : <AccountCircleIcon />}
                                    </Avatar>
                                </div>
                                {userData ? (
                                    <>
                                        <div style={{ marginTop: '10px', fontWeight: 'bold', textAlign: 'center' }}>
                                            Hi, {userData.firstName.trim()} {userData.lastName.trim()}!
                                        </div>
                                        <div style={{ fontSize: '14px', color: 'gray', textAlign: 'center' }}>
                                            {userData.email.trim()}
                                        </div>
                                        <div style={{ fontSize: '12px', color: 'gray', textAlign: 'center', marginTop: '5px' }}>
                                            {userData.companyName.trim()}
                                        </div>
                                    </>
                                ) : (
                                    <div>Loading...</div>
                                )}
                                <Link to="/profile" className="link-underline"
                                    style={{
                                        marginTop: '15px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        color: '#007bff',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Manage your VE Cloud Account
                                </Link>
                                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                    <LogoutIcon onClick={logout} style={{ cursor: 'pointer', color: '#dc3545' }} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Navbar>
        </>
    )
}

export default NavBar;