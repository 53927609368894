import React from "react";

import "../components/css/TotalSales.css";

const TotalSales = ({ totalTransaction }) => {
    return(
        <>
            <div className="totalSalesDiv">
                <div className="totalSalesLabel">
                    TRANSACTION TOTAL SALES
                </div>
                <div className="salesValue">
                    {totalTransaction?.trim() || "0"}
                </div>
            </div>
        </>
    )
};

export default TotalSales;