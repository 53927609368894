import { Button, CardActions, CardContent, CardHeader, Grid, InputAdornment, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { ImageField, ImageInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { useFormContext } from 'react-hook-form';
import { ClientHas } from '../Machines/RefreshMachineStatus';
import MROAccessGroupInput from '../Machines/MROAccessGroupInput';
import { doc, setDoc } from 'firebase/firestore';
import _extendLayout from '../../layout/_extendLayout';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const useStyles = makeStyles(theme => ({
    productImage: {
      objectFit: 'contain',
      width: '100%',
      height: 280,
      maxHeight: 'none',
    },
}));

const price = msg => (value) => {
    const regex = /^\d*(.\d{0,2})?$/;
    if(!regex.test(value)) return msg || "Invalid";
};

const CategoryInput = ({ client, defaultValue }) => {
    return (
        <ReferenceInput
            key={client}
            source="category"
            reference="categories"
            filter={{ client: client || "" }}
            filterToQuery={() => {}}
            fullWidth
            perPage={99999}
        >
            <SelectInput optionText="name" fullWidth validate={required("Please select a category.")} defaultValue={defaultValue} />
        </ReferenceInput>
    );
};

const LimitationRuleInput = ({ client, defaultValue }) => {
    return (
        <ReferenceInput
            key={client}
            source="limitationRule"
            reference="limitationRules"
            filter={{ client: client || "" }}
            filterToQuery={() => {}}
            perPage={99999}
            fullWidth
            resettable={true}
        >
            <SelectInput optionText="name" fullWidth defaultValue={defaultValue} />
        </ReferenceInput>
    );
};

const ProductEdit = () => {
    const { id } = useParams();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const form = useFormContext();
    const navigate = useNavigate();

    const [proDetails, setProDetails] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [mroAccess, setMroAccess] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
                await getProductById();
            }
        });
        return () => checkAuth();
    }, []);

    const getProductById = async () => {
        try {
            const res = await dataProvider.getOne("products", { id });
            setProDetails(res.data)
        } catch (err) {
            console.log(err)
        }
    };

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            setClientId(res.data?.client?.id);
        } catch (err) {
            console.log(err);
        }
    };

    const handleClientChange = () => {
        const categoryState = form.getFieldState("category");
        if(categoryState) {
            form.setValue("category", "");
        }

        const mroAccessState = form.getFieldState("mroAccessGroup");
        if(mroAccessState) {
            form.setValue("mroAccessGroup", []);
        }
    };

    const handleImgChange = async (e) => {
        const file = e?.target?.files?.[0];

        if (file) {
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleSave = async (datas) => {

        console.log(datas)

        const file = datas.image;
        let imgUrl = datas.image?.src ?? "";

        if (file && file.rawFile) {
            try {
                // Use the existing image name for the storage reference, so it overwrites the old image
                const storage = getStorage();
                const storageRef = ref(storage, `/products/${file.rawFile.name}`);

                // Upload the new image to Firebase Storage, overwriting the old image if it exists
                const uploadSnapshot = await uploadBytes(storageRef, file.rawFile);

                console.log("Upload complete:", uploadSnapshot);

                // Get the download URL of the uploaded image
                imgUrl = await getDownloadURL(uploadSnapshot.ref); // Save the new image URL
                console.log("New image URL:", imgUrl);
                setImageUrl(imgUrl);
            } catch (error) {
                console.error("Error uploading image:", error);
                alert("Failed to upload image. Please try again.");
                return;
            }
        }

        let obj = {
            category: datas.category,
            client: datas.client,
            description: datas.description ?? "",
            image: {
                src: imgUrl ?? "",
            },
            limitationRule: datas.limitationRule ?? null,
            name: datas.name ?? "",
            priceCash: datas.priceCash ?? 0,
            priceCredit: datas.priceCredit ?? 0,
            serialNumber: datas.serialNumber ?? "",
            sku: datas.sku ?? "",
            mroAccessGroup: mroAccess ?? [],
        };

        try{
            const docRef = doc(db, "products", id);

            await setDoc(docRef, obj, { merge: true });

            alert("Product saved successfully!");
            navigate("/products")
        } catch (err) {
            console.log("Error saving to Firebase:", err);
            alert("Failed to save product. Please try again.")
        }
    };

    if(!proDetails) {
        return <_extendLayout><p>Loading...</p></_extendLayout>
    }
    
    return (
        <>
            <_extendLayout>
                <div className="listMachines">
                    <CardHeader title="Edit Product" />
                    <CardContent>
                        <p>Product Details</p>
                        <SimpleForm toolbar={null} onSubmit={handleSave}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <Typography gutterBottom variant="h6">
                                        Product Image
                                    </Typography>
                                    <ImageInput
                                        source="image"
                                        accept="image/*"
                                        label=""
                                        fullWidth
                                        defaultValue={proDetails?.image?.src ?? ""}
                                        onChange={handleImgChange}
                                    >
                                        {/* <ImageField source="src" record={{ src: imagePreview ?? proDetails?.image?.src }} classes={{ image: classes.productImage }}  /> */}
                                        {imagePreview ? (
                                            <img
                                                src={imagePreview}
                                                alt="Image Preview"
                                                style={{ width: '100%', height: 'auto', maxHeight: 200 }}
                                            />
                                        ) : (
                                            <ImageField source="src" record={{ src: proDetails?.image?.src ?? "" }} classes={{ image: classes.productImage }} />
                                        )}
                                    </ImageInput>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        style={{ marginTop: "8px" }}
                                    >
                                        Recommended size: 720 x 720 px. Display ratio 1:1 png or jpg
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextInput label="Product Name" source="name" fullWidth validate={required("Please enter a name.")} defaultValue={proDetails.name ?? ""} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput label="Description" source="description" fullWidth defaultValue={proDetails.description ?? ""} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput label="SKU" source="sku" fullWidth defaultValue={proDetails.sku ?? ""} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput label="Serial Number" source="serialNumber" fullWidth defaultValue={proDetails.serialNumber ?? ""} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {isAdmin ? (
                                                <ReferenceInput
                                                    source="client"
                                                    reference="clients"
                                                    filterToQuery={() => {}}
                                                    onChange={handleClientChange}
                                                >
                                                    <SelectInput
                                                        optionText="companyName"
                                                        validate={required("Please select a client.")}
                                                        fullWidth
                                                        defaultValue={proDetails.client}
                                                    />
                                                </ReferenceInput>
                                            ) : (
                                                <TextInput
                                                    source="client"
                                                    label="Client"
                                                    fullWidth
                                                    defaultValue={clientId}
                                                    disabled
                                                    type="hidden"
                                                    style={{ display: "none" }}
                                                />
                                            )}
                                        </Grid>
                                        {/* category input */}
                                        {proDetails.client && (
                                            <>
                                                <Grid item xs={12}>
                                                    <CategoryInput client={proDetails.client || clientId} defaultValue={proDetails.category} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LimitationRuleInput client={proDetails.client || clientId} defaultValue={proDetails.limitationRule} />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} md={6}>
                                            <NumberInput
                                                source="priceCash"
                                                label="Price"
                                                fullWidth
                                                defaultValue={proDetails.priceCash ?? ""}
                                                validate={[
                                                    required("Please enter a price"),
                                                    price("Please enter a valid price with no more than two decimals.")
                                                ]}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                        {proDetails.client && (
                                            <ClientHas client={proDetails.client} module="mro" fallback={null}>
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <NumberInput
                                                            source="priceCredit"
                                                            label="Price (Credit)"
                                                            fullWidth
                                                            defaultValue={proDetails.priceCredit ?? ""}
                                                            validate={[
                                                                required("Please enter credit price"),
                                                                price("Please enter a valid credit price with no more than two decimals.")
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MROAccessGroupInput 
                                                            client={proDetails.client}
                                                            value={proDetails.mroAccessGroup || []} // Pass the current value or an empty array
                                                            initialValue={proDetails.mroAccessGroup || []}
                                                            onValueChange={(updatedValue) => {
                                                                setMroAccess(updatedValue)
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            </ClientHas>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} md={4}>
                                <Typography gutterBottom variant="h6">
                                    Product Infographic
                                </Typography>
                                <ImageInput source="infographic" accept="image/*" label="" helperText="Recommended size: 1080 x 1280 px.">
                                        <ImageField source="src" classes={{ image: classes.productImage }} />
                                </ImageInput>
                            </Grid> */}
                            <CardActions>
                                <Button type="submit" variant="contained" color="primary">
                                    Save
                                </Button>
                                <Button onClick={() => navigate(`/products`)} variant="contained">
                                    Back
                                </Button>
                            </CardActions>
                        </SimpleForm>
                    </CardContent>
                </div>
            </_extendLayout>
        </>
    );
}

export default ProductEdit;
