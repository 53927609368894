import React from "react";
import ProgressBarComponent from "./ProgressBar";

import "../components/css/OverallStatus.css";

const OverallStatus = ({ onlineData, stockData, errorData }) => {
    return(
        <>
            <h6 className="overallTitle">Overall status</h6>
            <div className="progressArea">
                <ProgressBarComponent progress={onlineData.onlinePercentage} label={"Online Machines"} value={onlineData.onlineVal} />
                <ProgressBarComponent progress={stockData.stockPercentage} label={"Stock Level"} value={stockData.stockVal} />
                <ProgressBarComponent progress={errorData.errorPercentage} label={"Slot Error"} value={errorData.errorVal} />
            </div>
        </>
    )
};

export default OverallStatus;