import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialog: {
        maxWidth: '100%',
        margin: 'auto',
    },
    table: {
        maxWidth: '100%',
    },
    keyCell: {
        minWidth: '200px',
        maxWidth: '200px',
    },
    valueCell: {
        minWidth: '250px',
        maxWidth: '250px',
    },
}));

const TRANSACTION = {
    GATEWAY: {
        '234': 'Alipay Scan',
        '305': 'WeChatPay CN Scan',
        '320': 'Boost Scan',
        '329': 'MCashMS',
        '336': 'TNG Scan',
        '338': 'UnionPay Scan',
        '347': 'GrabPay QR',
        '354': 'MaybankQR Scan',
        '379': 'GrabPay Scan',
        '164': 'PrestoPay Scan',
        '19': 'ShopeePay Scan',
    },
};

const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();

    return (
        <div className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose && (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            )}
        </div>
    );
};

const OpenCash = (value) => {
    const cashTypeArray = ["10 cent", "20 cent", "50 cent", "1 ringgit", "5 ringgit", "10 ringgit"];
    let totalCashReceived = value?.cashInDetails?.cashInTotal || 0;
    let totalCashReturned = value?.cashOutDetails?.cashOutTotal || 0;
    let balance = (totalCashReceived - totalCashReturned).toFixed(1);
    let totalCashTypeReceived = getCashType(value?.cashInDetails?.cashInTypeAndCount || {}, "received");
    let totalCashTypeReturned = getCashType(value?.cashOutDetails?.cashOutTypeAndCount || {}, "returned");

    return (
        <>
            <TableRow>
                <TableCell align="left"  colSpan="2">
                    <Typography variant="h5">Payment Details</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Payment Type</TableCell>
                <TableCell align="right" colSpan="3">
                    Cash
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell align="left" style={{ verticalAlign: "top" }} rowSpan={totalCashTypeReceived ? totalCashTypeReceived.length + 1: 2}>
                    Coin Type Received:
                </TableCell>
            </TableRow>
            {totalCashTypeReceived ? (
                totalCashTypeReceived.map((eachType, i) => (
                    <TableRow>
                        <TableCell align="right">{cashTypeArray[i]}</TableCell>
                        <TableCell align="right">=</TableCell>
                        <TableCell align="right">{eachType}</TableCell>
                    </TableRow>
                ))
            ) : (
                <TableRow>
                    <TableCell colSpan="3" align="right">
                        N/A
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell></TableCell>
                <TableCell align="right" style={{ fontWeight: 600 }} colSpan="2">
                    Total:
                </TableCell>
                {totalCashTypeReceived ? (
                    <TableCell align="right" style={{ fontWeight: 600 }}>
                        RM {totalCashReceived}
                    </TableCell>
                ) : (
                    <TableCell align="right" style={{ fontWeight: 600 }}>
                        N/A
                    </TableCell>
                )}
            </TableRow>

            <TableRow>
                <TableCell align="left" style={{ verticalAlign: "top" }} rowSpan={totalCashTypeReturned ? totalCashTypeReturned.length + 1 : 2}>
                    Coin Type Returned:
                </TableCell>
            </TableRow>
            {totalCashTypeReturned ? (
                totalCashTypeReturned.map((eachType, i) => (
                    <TableRow>
                        <TableCell align="right">{cashTypeArray[i]}</TableCell>
                        <TableCell align="right">=</TableCell>
                        <TableCell align="right">{eachType}</TableCell>
                    </TableRow>
                ))
            ) : (
                <TableRow>
                    <TableCell colSpan="3" align="right">
                        N/A
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell></TableCell>
                <TableCell align="right" style={{ fontWeight: 600 }} colSpan="2">
                    Total:
                </TableCell>
                {totalCashTypeReturned ? (
                    <TableCell align="right" style={{ fontWeight: 600 }}>
                        RM {totalCashReturned}
                    </TableCell>
                ) : (
                    <TableCell align="right" style={{ fontWeight: 600 }}>
                        N/A
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell align="right" style={{ fontWeight: 600 }} colSpan="2">
                    Balance:
                </TableCell>
                {totalCashTypeReceived && totalCashTypeReturned ? (
                    <TableCell align="right" style={{ fontWeight: 600 }}>
                        RM {balance}
                    </TableCell>
                ) : (
                    <TableCell align="right" style={{ fontWeight: 600 }}>
                        N/A
                    </TableCell>
                )}
            </TableRow>
        </>
    );
};

const OpenCashless = (value) => {
    return (
        <>
            <TableRow>
                <TableCell align="left" colSpan="2">
                    <Typography variant="h5">Payment Details</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Payment Type:</TableCell>
                <TableCell align="right">{checkNull(checkPaymentType(value))}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Transaction ID:</TableCell>
                <TableCell align="right">{checkNull(getTransactionID(value))}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Ref No:</TableCell>
                <TableCell align="right">{checkNull(getReferenceNo(value))}</TableCell>
            </TableRow>
        </>
    );
};

const OpenFailDetails = (value, classes) => {
    const [category, message] = getErrorCategoryMessage(value);

    return (
        <>
            <TableRow>
                <TableCell align="left" colSpan="1">
                    <Typography variant="h5">Error Detail</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Category:</TableCell>
                <TableCell align="right" colSpan="3">
                    {checkNull(category)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Message:</TableCell>
                <TableCell align="right" colSpan="3">
                    {checkNull(message)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left" className={classes.keyCell}>Description:</TableCell>
                <TableCell align="right" className={classes.valueCell} colSpan="3">
                    {checkNull(getErrorDetails(value))}
                </TableCell>
            </TableRow>
        </>
    );
};

const checkStatus = (v) => {
    if (!v || typeof v.Status === "undefined") {
        console.error("Invalid input to checkStatus function:", v);
        return null; // Or handle this case as appropriate
    }

    switch(v.Status) {
        case -3:
            return (
                <TableCell align="right" style={{ color: "#FF6562" }} colSpan="3">
                    Void
                </TableCell>
            );
        case -2:
            return (
                <TableCell align="right" style={{ color: "#FF6562" }} colSpan="3">
                    Dropped
                </TableCell>
            );
        case -1:
            return (
                <TableCell align="right" style={{ color: '#C0C0C0' }} colSpan="3">
                    Canceled
                </TableCell>
            );
        case 0:
            return (
                <TableCell align="right" style={{ color: '#FF6562' }} colSpan="3">
                    Failed
                </TableCell>
            );
        case 1:
            return (
                <TableCell align="right" style={{ color: '#00C07F' }} colSpan="3">
                    Successful
                </TableCell>
            );
    }
};

const checkPaymentType = record => {
    if(record?.payment?.gatewayID) {
        return TRANSACTION.GATEWAY?.[record.payment.gatewayID] || "N/A";
    }

    if(record?.payment?.terminal) {
        return "Paywave";
    }

    if(record?.payment?.ghlPaymentDetail?.paymentResponse?.msg?.productCode) {
        return record.payment.ghlPaymentDetail.paymentResponse.msg.productCode;
    }

    return "N/A";
};

const getTransactionID = (record) => {
    if(record?.payment?.transactionId) {
        return record.payment.transactionId;
    }

    if(record?.payment?.ghlPaymentDetail?.paymentResponse?.msg?.txnRef) {
        return record.payment.ghlPaymentDetail.paymentResponse.msg.txnRef;
    }

    return "N/A";
};

const getReferenceNo = (record) => {
    if(record?.payment?.refNo) {
        return record.payment.refNo;
    }

    if(record?.payment?.ghlPaymentDetail?.paymentResponse?.msg?.retTxnRef) {
        return record.payment.ghlPaymentDetail.paymentResponse.msg.retTxnRef;
    }

    return "N/A";
};

const getErrorCategoryMessage = (record) => {
    const errorList = [
        {
            category: 'Hardware',
            key: ['sensor got blocked'],
            message: 'sensor got blocked',
        },
        {
            category: 'Hardware',
            key: ['driver board went wrong'],
            message: 'Item Not Detect Dropped',
        },
        {
            category: 'Hardware',
            key: ['data does not have 30 count'],
            message: 'Driver board communication went wrong',
        },
        {
            category: 'System',
            key: ['signature not match'],
            message: 'Signature not match',
        },
        {
            category: 'Customer',
            key: [
                'balance is not enough',
                'insufficient fund',
                'insufficient balance',
                'balance not sufficient',
                'USER NOT ENOUGH BALANCE',
            ],
            message: "Customer's e-wallet insufficient balance",
        },
        {
            category: 'Customer',
            key: [
                '40913:consumer presented code is expired',
                '40912:consumer presented code is invalid',
                'Payment code not found/expired',
                'Incorrect Barcode',
                'CODE IS SCANNED BEFORE',
                'Invalid / Expired Customer Token',
                'Payment code used/expired',
                'Invalid Barcode/QR',
                'auth code already used',
                'auth code illegal',
                'Awaiting user to pay',
            ],
            message: "Customer's presented code was invalid",
        },
        {
            category: 'Customer',
            key: ['Customer account is disabled by Alipay'],
            message: 'Customer account is disabled by Alipay',
        },
        {
            category: 'Merchant / Ipay',
            key: [
                'Payment not allowed',
                'Payment Fail',
                'Payment Fail (PAYMENT_FAIL)',
                'Payment fail (00000000)',
                'Payment fail ()',
                'Payment Fail (INVALID_QR_CODE)',
                'Payment Fail (REQUEST_FORMAT_ERROR)',
                'Payment Fail (No Response - Payment)',
                'Payment Fail (PENDING_CUST_ACTION)',
                'Payment fail (20)',
                'Payment fail (57)',
                'Payment Fail (QRC_EXPIRED)',
                'The barcode or QR code scanned by cashiers is not the one',
                'Payment Fail (KEY_EXPIRED)',
                'Payment fail (NOT_SUPPORT_PAYMENT_INST)',
            ],
            message: 'Payment was not allowed by payment gateway',
        },
        {
            category: 'System',
            key: [
                'UnknownHostException',
                'SocketTimeoutException',
                "Element 'head' does not have a match in class com.vechnology.ve.models.payment.data.ResponseEnvelope",
                'unexpected end of stream on Connectunexpected end of stream on Connection',
                'ConnectException',
                'SSLHandshakeException',
                'Internal Connection Timeout',
            ],
            message: 'Network connection issue',
        },
        {
            category: 'Merchant / Ipay',
            key: [
                '5001:server_error',
                'Response result is null',
                'Product toggle is disabled for merchant',
                'there is risk for payment request',
                'System error',
                'A server error occurred',
                'Payment method not supported',
                'System interruption',
                '40983:amount exceeds periodically daily limit of MYR',
                'channel not enable',
            ],
            message: "Payment's gateway error",
        },
    ];

    const errorDescription = record?.error?.description || "";

    if(!errorDescription) {
        return [null, null];
    }

    for(const error of errorList) {
        for(const key of error.key) {
            if(errorDescription.toLowerCase().includes(key.toLowerCase())) {
                if(key.toLowerCase() === "driver board went wrong") {
                    const driverBoardResponse = record?.responseStatus || {};
                    const { globalDroppedSensor, itemDroppedSensor, motorTurned, itemDropped } = driverBoardResponse;

                    if(!globalDroppedSensor && !itemDroppedSensor) {
                        return [error.category, `${error.message}--sensor off`];
                    }

                    if(!motorTurned) {
                        return [error.category, `${error.message}--motor didn't spin`];
                    }

                    if(!itemDropped) {
                        return [error.category, `${error.message}--sensor didn't detect drop`];
                    }
                }
                return [error.category, error.message];
            }
        }
    }
    return [null, null];
};

const getErrorDetails = (value) => {
    let errorDescription = value?.error?.description || "";
    errorDescription = errorDescription
        .replace(/com\.vechnology\.ve\.models\.payment\./g, '*')
        .replace(/com\.vechnology\.ve\.models\.serial\.driverBoard\./g, '*')
        .replace(/com\.vechnology\.ve/g, '*')
        .substring(0, 200);
    return `${errorDescription}...`;
};

const getTime = (dateTime, type) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "Jun",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const date = new Date(dateTime);

    if(type === "date") {
        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    }
    if(type === "time") {
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    }

    return "N/A";
};

const generateCashTypeArray = (cashTypeObject, arrayType) => {
    const receivedArray = ["0.1", "0.2", "0.5", "1.0", "5.0", "10.0"];
    const returnedArray = ["0.1", "0.2", "0.5"];
    const selectedArray = arrayType === "received" ? receivedArray : returnedArray;

    return selectedArray.map((type) => {
        const value = cashTypeObject?.[type] || 0;
        return value.toString();
    });
};

const getCashType = (cashTypeObject, transType) => {
    if(!cashTypeObject) return [];
    switch(transType) {
        case "received":
            return generateCashTypeArray(cashTypeObject, "received");
        case "returned":
            return generateCashTypeArray(cashTypeObject, "returned");
        default:
            return [];
    }
};

const getProductSlot = (record) => {
    const rowArray = ["A", "B", "C", "D", "E", "F"];
    if(record === null) return "N/A";
    let row = Math.floor(record / 12);
    let column = record % 12;
    return `${rowArray[row]}${column}`;
};

const checkNull = (value) => (value != null ? value : "N/A");

export default function CustomizedDialogs({ record, onClose, open }) {
    const value = record;
    const classes = useStyles();

    return (
        <>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} className={classes.root}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    Id: {value?.id ?? "N/A"}
                </DialogTitle>
                <DialogContent>
                    <Table className={classes.table}>
                        <TableBody colSpan="2">
                            <TableRow>
                                <TableCell align='left' className={classes.keyCell} colSpan="2">
                                    <Typography variant="h5">Transaction Details</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Machine UUID: </TableCell>
                                <TableCell align="right" colSpan="3">
                                    {checkNull(value?.Machine?.machineUUID)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Date: </TableCell>
                                <TableCell align="right" colSpan="3">
                                    {checkNull(getTime(value?.Date, "date"))}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Time: </TableCell>
                                <TableCell align="right" colSpan="3">
                                    {checkNull(getTime(value?.Date, "time"))}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Product Name: </TableCell>
                                <TableCell align="right" colSpan="3">
                                    {checkNull(value?.Product?.name)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Product Slot: </TableCell>
                                <TableCell align="right" colSpan="3">
                                    {checkNull(getProductSlot(value?.Product?.slot))}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Product Price: </TableCell>
                                <TableCell align="right" colSpan="3">
                                    RM {checkNull(value?.Amount)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Status: </TableCell>
                                {checkStatus(value)}
                            </TableRow>
                            {value?.Status === 1 ? <></> : <OpenFailDetails classes={classes} {...value} />}
                            {value?.Payment?.cash ? <OpenCash {...value} /> : <OpenCashless {...value} />}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    );
};