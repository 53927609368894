import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { CreateButton, Datagrid, ExportButton, List, ReferenceField, TextField, TopToolbar, useDataProvider } from 'react-admin';
import { auth } from '../../firebase';
import { CardContent, CardHeader } from '@material-ui/core';
import CategoryGroupField from './CategoryGroupField';
import SearchInput from '../SearchInput';
import _extendLayout from '../../layout/_extendLayout';

const exporter = async (datas, clientId) => {
    const headers = [
        "ID",
        "Client ID",
        "Created At",
        "Name",
        "Updated At",
    ];

    let csvRows = [headers.join(",")];

    const filterData = datas.filter((e) => e.client === clientId);

    filterData.forEach(e => {
        
        const formattedCreatedAt = e.createdAt?.toDate
            ? `"${e.createdAt.toDate().toLocaleString()}"`
            : e.createdAt?.seconds
                ? `"${new Date(e.createdAt.seconds * 1000).toLocaleString()}"`
                : '""';

        const formattedUpdatedAt = e.updatedAt?.toDate
            ? `"${e.updatedAt.toDate().toLocaleString()}"`
            : e.updatedAt?.seconds
                ? `"${new Date(e.updatedAt.seconds * 1000).toLocaleString()}"`
                : '""';

        const commonDetails = [
            e.id,
            e.client ?? "",
            formattedCreatedAt,
            e.name ?? "",
            formattedUpdatedAt,
        ];
        csvRows.push([...commonDetails].join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "categories.csv";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const CustomActions = ({ searchInput, clientId, ...props }) => (
    <TopToolbar style={{ width: "100%", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
            {searchInput}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
            <CreateButton label="Create" />
            <ExportButton label="Export" {...props} exporter={(datas) => exporter(datas, clientId)} />
        </div>
    </TopToolbar>
);

const CategoryList = (props) => {

    const dataProvider = useDataProvider();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState("");
    const [catList, setCatList] = useState(null);
    const [filteredData, setFilteredData] = useState(catList || []);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                getCurrentUser(user.uid);
            }
        })

        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        const res = await dataProvider.getOne("users", { id: userId });
        setIsAdmin(res.data.role === "admin")
        setClientId(res.data?.client?.id);
        await getCatogeryList(res.data?.client?.id);
    };

    const getCatogeryList = async (clientId) => {
        const res = await dataProvider.getList("categories", {
            filter: { "client": clientId },
            sort: { field: "createdAt", order: "DESC" },
            pagination: { page: 1, perPage: 1000 },
        });
        setCatList(res.data);
        setFilteredData(res.data);
    };

    const hanldeFilteredData = (newFilteredData) => {
        setFilteredData(newFilteredData)
    };

    return (
        <>
            <_extendLayout>
                <CardContent>
                    {catList && (
                        <div style={{ width: '90%' }}>
                            <CardHeader title="List of Category" />
                            <List
                                resource="categories"
                                actions={<CustomActions clientId={clientId} searchInput={<SearchInput searchKeys={["name"]} data={catList} onFilteredData={hanldeFilteredData} />} />}
                                perPage={25}
                                data={filteredData}
                                filter={isAdmin ? { "client": "abc" } : { "client": clientId }}
                            >
                                <Datagrid data={filteredData} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                                    <TextField source="name" textAlign="left" />
                                    {isAdmin && (
                                        <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                            <TextField source="companyName" />
                                        </ReferenceField>
                                    )}
                                    <CategoryGroupField textAlign="right" />
                                </Datagrid>
                            </List>
                        </div>
                    )}
                </CardContent>
            </_extendLayout>
        </>
    );
}

export default CategoryList;