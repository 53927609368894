import { Button, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { ReferenceInput, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import _extendLayout from '../../layout/_extendLayout';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { addDoc, collection } from 'firebase/firestore';

const CategoryCreate = () => {

    const dataProvider = useDataProvider();
    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState("");


    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                getCurrentUser(user.uid)
            }
        });

        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            setClientId(res.data.client.id);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSave = async (d) => {
        let obj = {
            client: d.client || clientId,
            name: d.name ?? "",
            createdAt: new Date(),
            updatedAt: new Date(),
        };

        try {
            const catCollectionRef = collection(db, "categories");
            await addDoc(catCollectionRef, obj);

            alert("Category saved successfully!");
            navigate("/categories");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <_extendLayout>
                <div className="listMachines">
                    <CardHeader title="Create Category" />
                    <CardContent>
                        <p>Category Details</p>
                        <SimpleForm toolbar={null} onSubmit={handleSave}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextInput label="Name" source="name" fullWidth validate={required("Please enter a name")} />
                                </Grid>
                                <Grid item xs={12}>
                                    {isAdmin ? (
                                        <ReferenceInput
                                            source="client"
                                            reference="clients"
                                            fullWidth
                                            perPage={9999}
                                            filterToQuery={() => {}}
                                        >
                                            <SelectInput
                                                optionText="companyName"
                                                variant="outlined"
                                                fullWidth
                                                margin="none"
                                                perPage={9999}
                                                validate={required("Please select a client")}
                                            />
                                        </ReferenceInput>
                                    ) : (
                                        <input type="hidden" value={clientId} />
                                    )}
                                </Grid>
                            </Grid>
                            <CardActions>
                                <Button type="submit" variant="contained" color="primary">
                                    Save
                                </Button>
                            </CardActions>
                        </SimpleForm>
                    </CardContent>
                </div>
            </_extendLayout>
        </>
    );
}

export default CategoryCreate;
