import React, { useCallback, useEffect, useState } from 'react'
import { Datagrid, FunctionField, List, NumberField, TextField, TopToolbar } from 'react-admin';
import { Button, Chip, Modal, Typography } from '@material-ui/core';
import { getPrice, getProductName, getRefId, getTotalCashReceived, getTotalCashReturned, getTransId, renderDate, renderGatewayField, renderProductSlot, renderTime, renderTransactionStatus, statusForExcelReport } from '../../Employee/ValidateCSV';
import CustomizedDialogs from './TransactionDetails';
import { makeStyles } from "@material-ui/core/styles";
import TransactionFilter from '../../Filters/TransactionFilter';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { Pagination, Tab, Table, Tabs } from "react-bootstrap";
import _extendLayout from '../../../layout/_extendLayout';
import axios from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const CustomPagination = ({ total, currentPage, perPage, onPageChange }) => {
    const totalPages = Math.ceil(total / perPage);

    const handlePageChange = (page) => {
        if(page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <Pagination>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </Pagination>
    )
    
};

const renderAdminMoreButtonField = (record, setDetailsOpen, setModalContent) => {
    if(!record) return null;
    let parsedResponseStatus = record.ResponseStatus ? JSON.parse(record.ResponseStatus) : null;

    return (
        <Button
            size="medium"
            onClick={() => {
                setDetailsOpen(true);

                const modalContent = {
                    ...record,
                    ResponseStatus: parsedResponseStatus
                };

                setModalContent(JSON.stringify(modalContent, null, 2))
            }}
        >
            &gt;
        </Button>
    );
};

const renderCustomerMoreButtonField = (record, setDetailsOpen, setModalContent) => {
    if(!record) return null;
    return (
        <button
            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
            size="medium"
            onClick={() => {
                setDetailsOpen(true);
                setModalContent(
                    <CustomizedDialogs
                        record={record}
                        open={true}
                        onClose={() => setDetailsOpen(false)}
                    />
                );
            }}
        >
            &gt;
        </button>
    );
};

const AdminList = ({ data, renderMoreButton, handleSortChange, sortField, sortOrder }) => {
    
    if(!data || data.length === 0) return <Typography>No data found</Typography>;
    
    return (
        <Datagrid data={data} bulkActionButtons={false}>
            <TextField label="Machine UUID" source="Machine.machineUUID" sort={sortField === "Machine.machineUUID" ? sortOrder : false} onClick={() => handleSortChange("Machine.machineUUID", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Date" render={renderDate} />
            <FunctionField label="Time" render={renderTime} />
            <TextField label="Company Name" source="companyName" />
            <TextField label="Product Name" source="Product.name" sort={sortField === "Product.name" ? sortOrder : false} onClick={() => handleSortChange("Product.name", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Product Slot" render={renderProductSlot} />
            <NumberField source="Amount" label="Product Price" locales="en-MY" options={{ style: "currency", currency: "MYR" }} sort={sortField === "Amount" ? sortOrder : false} onClick={() => handleSortChange("Amount", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Gateway" render={renderGatewayField} />
            <FunctionField label="Status" render={renderTransactionStatus} />
            <FunctionField label="" render={renderMoreButton} />
        </Datagrid>
    );
};

const ClientList = ({ data, renderMoreButton, handleSortChange, sortField, sortOrder }) => {
    if(!data || data.length === 0) return <Typography>No data found</Typography>;

    return (
        <Datagrid data={data} bulkActionButtons={false} sx={{ "& .RaDatagrid-headerCell": { fontWeight: "bold" } }}>
            <TextField label="Machine UUID" source="Machine.machineUUID" />
            <FunctionField label="Date" render={renderDate} />
            <FunctionField label="Time" render={renderTime} />
            <TextField label="Product Name" source="Product.name" sort={sortField === "Product.name" ? sortOrder : false} onClick={() => handleSortChange("Product.name", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Product Slot" render={renderProductSlot} />
            <NumberField source="Amount" label="Product Price" locales="en-MY" options={{ style: "currency", currency: "MYR" }} sort={sortField === "Amount" ? sortOrder : false} onClick={() => handleSortChange("Amount", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Gateway" render={renderGatewayField} />
            <FunctionField label="Status" render={renderTransactionStatus} />
            <FunctionField label="" render={renderMoreButton} />
        </Datagrid>
    );
};

const AdminOrderList = ({ data, renderMoreButton, handleSortChange, sortField, sortOrder }) => {
    if(!data || data.length === 0) return <Typography>No data found</Typography>;

    return (
        <Datagrid
            data={data}
            bulkActionButtons={false}
        >
            <TextField label="Order ID" source="OrderNo" />
            <TextField label="Transaction ID" source="TransactionId" />
            <TextField label="Machine UUID" source="Machine.machineUUID" sort={sortField === "Machine.machineUUID" ? sortOrder : false} onClick={() => handleSortChange("Machine.machineUUID", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Date" render={renderDate} />
            <FunctionField label="Time" render={renderTime} />
            <TextField label="Company Name" source="companyName" />
            <TextField label="Product Name" source="Product.name" sort={sortField === "Product.name" ? sortOrder : false} onClick={() => handleSortChange("Product.name", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Product Slot" render={renderProductSlot} />
            <NumberField source="Amount" label="Product Price" locales="en-MY" options={{ style: "currency", currency: "MYR" }} sort={sortField === "Amount" ? sortOrder : false} onClick={() => handleSortChange("Amount", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Gateway" render={renderGatewayField} />
            <FunctionField label="Status" render={renderTransactionStatus} />
            <FunctionField label="" render={renderMoreButton} />
        </Datagrid>
    );
};

const ClientOrderList = ({ data, renderMoreButton, handleSortChange, sortField, sortOrder }) => {
    if(!data || data.length === 0) return <Typography>No data found</Typography>;

    return (
        <Datagrid
            data={data}
            bulkActionButtons={false}
            sx={{ "& .RaDatagrid-headerCell": { fontWeight: "bold" } }}
        >
            <TextField label="Order ID" source="OrderNo" />
            <TextField label="Transaction ID" source="TransactionId" />
            <TextField label="Machine UUID" source="Machine.machineUUID" sort={sortField === "Machine.machineUUID" ? sortOrder : false} onClick={() => handleSortChange("Machine.machineUUID", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Date" render={renderDate} />
            <FunctionField label="Time" render={renderTime} />
            <TextField label="Product Name" source="Product.name" sort={sortField === "Product.name" ? sortOrder : false} onClick={() => handleSortChange("Product.name", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Product Slot" render={renderProductSlot} />
            <NumberField source="Amount" label="Product Price" locales="en-MY" options={{ style: "currency", currency: "MYR" }} sort={sortField === "Amount" ? sortOrder : false} onClick={() => handleSortChange("Amount", sortOrder === "asc" ? "desc" : "asc")} />
            <FunctionField label="Gateway" render={renderGatewayField} />
            <FunctionField label="Status" render={renderTransactionStatus} />
            <FunctionField label="" render={renderMoreButton} />
        </Datagrid>
    );
};

const SalesTransactionList = (props) => {
    const classes = useStyles();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState("");
    const [status, setStatus] = useState(null);
    const [salesList, setSalesList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(1000);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [orderList, setOrderList] = useState([]);
    const [filteredOrderData, setFilteredOrderData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("transaction");
    const [sortField, setSortField] = useState('Date');
    const [sortOrder, setSortOrder] = useState('DESC');
    

    useEffect(() => {
        let filter = getCurrentUser();
        setTimeout(async () => {
            await getTransactionList(filter, { page: currentPage, perPage: perPage }, { field: "Date", order: "DESC" });
            await getOrderList();
        }, 2000);
    }, [isAdmin]);

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const getCurrentUser = async () => {
        try {
            let token = localStorage.getItem("token");

            const res = decodeJWT(token);
            const isAdminUser = res.role === "admin";
            setIsAdmin(isAdminUser);
            
            if(!isAdminUser) setClientId(res.client_id);

            return isAdminUser
                ? { mode: "commercial" }
                : { client: res.client_id, mode: "commercial" };
        } catch (err) {
            console.log(err);
        }
    };

    const decodeJWT = (token) => {
        const parts = token.split(".");

        if (parts.length !== 3) {
            throw new Error("Invalid token format");
        }

        const payload = parts[1];
        return JSON.parse(base64Decode(payload));
    };

    const base64Decode = (str) => {
        const padding = '='.repeat((4 - (str.length % 4)) % 4);
        const base64 = (str + padding).replace(/-/g, '+').replace(/_/g, '/');
        return decodeURIComponent(escape(atob(base64)));
    };

    const getTransactionList = async (filter, pagination, sort) => {
        setLoading(true);
        try {
            let token = localStorage.getItem("token");
            let pagination = { skip: 0, limit: 10 };
            let sortField = sort.field || "Date";
            let sortOrder = sort.order || "DESC";
            let params = {
                ...filter,
                skip: (pagination.page - 1) * pagination.perPage,
                limit: pagination.perPage,
                sortField: sortField,
                sortOrder: sortOrder,
            };

            if(isAdmin === false) {
                params.skip = 0;
                params.limit = 10;
                // params.status = 1;
            }
            params.mode = "commercial";

            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/machine_transaction/salesList`, {
                params,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            
            const newFilteredData = res.data.result.data;
            
            if (Array.isArray(newFilteredData)) {
                const formattedData = newFilteredData.map(item => ({
                    ...item,
                    id: item.TransactionId,
                }));
            
                setFilteredData(formattedData);
                setSalesList(formattedData);
                setTotal(formattedData.length);
                setTotalAmount(formattedData.reduce((sum, item) => sum + (parseFloat(item.Amount) || 0), 0));
            } else {
                console.error("Invalid data format:", newFilteredData);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const getOrderList = async () => {
        setLoading(true);
        try {
            let token = localStorage.getItem("token");

            let params = { mode: "commercial", skip: 0, limit: 10 };

            if(isAdmin === false) {
                params.status = 1;
                params.skip = 0;
                params.limit = 10;
            }
            
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/machine_transaction/orderList`, {
                params,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            const newFilteredData = res.data.result.data;
            if (Array.isArray(newFilteredData)) {
                const formattedData = newFilteredData.map(item => ({
                    ...item,
                    id: item.TransactionId,
                }));
                
                setFilteredOrderData(formattedData);
                setOrderList(formattedData);
            } else {
                console.error("Invalid data format:", newFilteredData);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const CustomActions = React.memo(({ totalAmount = 0, showTotalAmount = true,  ...props }) => {

        const combineExporter = () => {
            if(activeTab === "transaction") {
                return props.salesExporter(props.salesList);
            } else {
                return orderExporter(props.orderList);
            }
        };

        return (
            <TopToolbar style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
                    {/* Sum of product price */}
                    {showTotalAmount && (
                        <Typography variant="h6">
                            Total Price: RM {totalAmount.toFixed(2)}
                        </Typography>
                    )}
                </div>
                {/* Render Filter Tags */}
                {appliedFilters.map((filter, index) => (
                    <Chip
                        key={index}
                        label={`${filter.key} = ${filter.value}`}
                        // onDelete={() => removeFilter(filter.key)}
                        color="primary"
                    />
                ))}
                <Button
                    startIcon={<FilterListIcon />}
                    onClick={() => props.onShowFilters()}
                    variant="outlined"
                    color="primary"
                    style={{ border: "none" }}
                >
                    Show Filters
                </Button>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <TransactionFilter
                        setFilteredData={handleFilteredData}
                        allData={salesList}
                        onModalOpenChange={handleModalOpenChange}
                        modalOpen={modalOpen}
                        setAppliedFilters={setAppliedFilters}
                        setTotalAmount={setTotalAmount}
                    />
                    <Button color="primary" startIcon={<FileDownloadIcon />} onClick={combineExporter}>
                        Export
                    </Button>
                </div>
            </TopToolbar>
        );
    });

    const handleModalOpenChange = useCallback((isOpen) => {
        setModalOpen(isOpen);
    }, []);

    const handleFilteredData = (newFiltered) => {
        const hasOrderNo = newFiltered.some(item => item.OrderNo !== undefined);
        
        if(hasOrderNo) {
            setFilteredOrderData(newFiltered);
            setFilteredData(newFiltered);
        } else {
            setFilteredOrderData(newFiltered);
            setFilteredData(newFiltered);
        }
    };

    const salesExporter = async () => {
        
        try {

            const headers = [
                "Document ID",
                "Machine UUID",
                "Date",
                "Time",
                "Product",
                "Slot",
                "Price",
                "Gateway",
                "Status",
                "Total Cash Received",
                "Total Cash Returned",
                "Balance",
                "Transaction ID",
                "Reference No",
            ];

            let csvRows = [headers.join(",")];

            const filterData = salesList.filter((e) => e.Client === clientId);
            
            filterData.forEach(e => {

                const date = renderDate(e);
                const time = renderTime(e);
                const slot = renderProductSlot(e);
                const gateway = renderGatewayField(e);
                const status = statusForExcelReport(e.Status);
                const totalCashReceived = getTotalCashReceived(e);
                const totalCashReturned = getTotalCashReturned(e);
                const transId = getTransId(e);
                const refId = getRefId(e);
                const balance = totalCashReceived && totalCashReturned ? totalCashReceived - totalCashReturned : "";
                const productName = getProductName(e);
                const price = getPrice(e);

                const commonDetails = [
                    e.id,
                    e.Machine.machineUUID ?? "",
                    date ?? "",
                    time ?? "",
                    productName ?? "",
                    slot ?? "",
                    price ?? "",
                    gateway ?? "",
                    status ?? "",
                    totalCashReceived ?? "",
                    totalCashReturned ?? "",
                    balance ?? "",
                    transId ?? "",
                    refId ?? "",
                ];
                csvRows.push([...commonDetails].join(","));
            });

            const csvContent = csvRows.join("\n");
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Transactions.csv";
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch(err) {
            console.log(err);
        }
    };

    const orderExporter = async () => {
        try {
            const headers = [
                "Order ID",
                "Document ID",
                "Machine UUID",
                "Date",
                "Time",
                "Product",
                "Slot",
                "Price",
                "Gateway",
                "Status",
                "Total Cash Received",
                "Total Cash Returned",
                "Balance",
                "Transaction ID",
                "Reference No",
            ];

            let csvRows = [headers.join(",")];
            
            orderList.forEach(e => {
                const date = renderDate(e);
                const time = renderTime(e);
                const slot = renderProductSlot(e);
                const gateway = renderGatewayField(e);
                const status = statusForExcelReport(e.Status);
                const totalCashReceived = getTotalCashReceived(e);
                const totalCashReturned = getTotalCashReturned(e);
                const transId = getTransId(e);
                const refId = getRefId(e);
                const balance = totalCashReceived && totalCashReturned ? totalCashReceived - totalCashReturned : "";
                const productName = getProductName(e);
                const price = getPrice(e);

                const commonDetails = [
                    e.OrderNo,
                    e.id,
                    e.Machine.machineUUID ?? "",
                    date ?? "",
                    time ?? "",
                    productName ?? "",
                    slot ?? "",
                    price ?? "",
                    gateway ?? "",
                    status ?? "",
                    totalCashReceived ?? "",
                    totalCashReturned ?? "",
                    balance ?? "",
                    transId ?? "",
                    refId ?? "",
                ];
                csvRows.push([...commonDetails].join(","));
            });

            const csvContent = csvRows.join("\n");
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Order Transactions.csv";
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (err) {
            console.log(err);
        }
    };

    const handleSearchInput = (query) => {
        if(query) {
            const filtered = salesList.filter((item) =>
                ["machine.machineUUID"].some((key) => {
                    const value = key.split(".").reduce((acc, part) => acc && acc[part], item);
                    return value && value.toString().toLowerCase().includes(query.toLowerCase());
                })
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(salesList);
        }
    };

    const handleSortChange = (field, order) => {
        setSortField(field);
        setSortOrder((prevSortOrder) => {
            const newOrder = prevSortOrder === "asc" ? "desc" : "asc";
            console.log(`New sort order: ${newOrder}`);
            return newOrder;
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let filter = isAdmin
                ? { mode: "commercial" }
                : { client: clientId, mode: "commercial" }
            let params = {
                sortField,
                sortOrder,
            }
            
            getTransactionList(filter, { page: currentPage, perPage: perPage }, { field: params.sortField, order: params.sortOrder });
        };

        fetchData();
    }, [currentPage, sortField, sortOrder]);   

    let filter = isAdmin ? { mode: "commercial" } : { client: clientId };
    
    return (
        <>
            <_extendLayout isModalOpen={modalOpen || detailsOpen}>
                <div style={{ width: '90%' }}>
                    <h4 className=''>Sales Transaction Report</h4>
                    <Tabs defaultActiveKey="transaction" className="m-3" onSelect={handleTabChange}>
                        <Tab eventKey="transaction" title="Transaction List">
                            {loading ? (
                                <Typography>Loading...</Typography>
                            ) : salesList.length === 0 ? (
                                <Typography>No data found</Typography>
                            ) : (
                                <div>
                                    <CustomActions 
                                        totalAmount={totalAmount}
                                        showTotalAmount={true}
                                        onShowFilters={() => setModalOpen(true)}
                                        handleModalOpenChange={handleModalOpenChange}
                                        isModalOpen={modalOpen}
                                        setFilteredData={setFilteredData}
                                        data={salesList}
                                        salesExporter={salesExporter}
                                        setTotalAmount={setTotalAmount}
                                        activeTab={activeTab}
                                    />
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Machine UUID</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                {isAdmin && (
                                                    <th>Company Name</th>
                                                )}
                                                <th>Product Name</th>
                                                <th>Product Slot</th>
                                                <th>Product Price</th>
                                                <th>Gateway</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.Machine.machineUUID}</td>
                                                    <td>{renderDate(item)}</td>
                                                    <td>{renderTime(item)}</td>
                                                    {isAdmin && (
                                                        <td>{item.companyName}</td>
                                                    )}
                                                    <td>{item.Product.name}</td>
                                                    <td>{renderProductSlot(item)}</td>
                                                    <td>{item.Amount}</td>
                                                    <td>{renderGatewayField(item)}</td>
                                                    <td>{renderTransactionStatus(item)}</td>
                                                    <td>
                                                        {isAdmin ? (
                                                            renderAdminMoreButtonField(item, setDetailsOpen, setModalContent)
                                                        ) : (
                                                            renderCustomerMoreButtonField(item, setDetailsOpen, setModalContent)
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                // <List
                                //     resource='customResource'
                                //     data={filteredData}
                                //     perPage={25}
                                //     currentPage={currentPage}
                                //     actions={
                                //         <CustomActions 
                                //             totalAmount={totalAmount}
                                //             showTotalAmount={true}
                                //             onShowFilters={() => setModalOpen(true)}
                                //             handleModalOpenChange={handleModalOpenChange}
                                //             isModalOpen={modalOpen}
                                //             setFilteredData={setFilteredData}
                                //             data={salesList}
                                //             salesExporter={salesExporter}
                                //             setTotalAmount={setTotalAmount}
                                //             activeTab={activeTab}
                                //         />
                                //     }
                                //     empty={false}
                                // >
                                //     {isAdmin ? <AdminList data={filteredData} renderMoreButton={(record => renderAdminMoreButtonField(record, setDetailsOpen, setModalContent))} handleSortChange={handleSortChange} sortField={sortField} sortOrder={sortOrder} /> : <ClientList data={filteredData} renderMoreButton={(record => renderCustomerMoreButtonField(record, setDetailsOpen, setModalContent))} handleSortChange={handleSortChange} sortField={sortField} sortOrder={sortOrder} /> }
                                // </List>
                            )}
                            {/* <CardHeader title="Sales Transaction List" style={{ paddingLeft: "4px" }} />
                            <List
                                {...props}
                                resource="transactions"
                                filter={currentFilter}
                                sort={{ field: "date", order: "DESC" }}
                                // filters={<SearchInput searchKeys={["machine.machineUUID"]} onFilteredData={handleSearchInput} />}
                                perPage={25}
                                actions={
                                    <CustomActions 
                                        totalAmount={totalAmount}
                                        onShowFilters={() => setModalOpen(true)}
                                        handleModalOpenChange={handleModalOpenChange}
                                        isModalOpen={modalOpen}
                                        setFilteredData={setFilteredData}
                                        data={salesList}
                                        salesExporter={salesExporter}
                                    />
                                }
                            >
                                <Datagrid data={filteredData.length > 0 ? filteredData.slice((currentPage - 1) * perPage, currentPage * perPage) : []} bulkActionButtons={false} sx={{ "& .RaDatagrid-headerCell": { fontWeight: "bold" } }}>
                                    <TextField label="Machine UUID" source="machine.machineUUID" />
                                    <FunctionField label="Date" render={(record) => renderDate(record)} />
                                    <FunctionField label="Time" render={(record) => renderTime(record)} />
                                    {isAdmin && (
                                        <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                            <TextField source="companyName" />
                                        </ReferenceField>
                                    )}
                                    <TextField label="Product Name" source="product.name" />
                                    <FunctionField label="Product Slot" render={renderProductSlot} />
                                    <NumberField
                                        source="amount"
                                        label="Product Price"
                                        locales="en-MY"
                                        textAlign="left"
                                        options={{ style: "currency", currency: "MYR" }} 
                                    />
                                    <FunctionField label="Gateway" render={(record) => renderGatewayField(record)} />
                                    <FunctionField label="Status" render={(record) => renderTransactionStatus(record)} />
                                    {isAdmin ? (
                                        <FunctionField label="" render={(record) => renderAdminMoreButtonField(record)} />
                                    ) : (
                                        <FunctionField label="" render={(record) => renderCustomerMoreButtonField(record)} />
                                    )}
                                </Datagrid>
                            </List> */}
                        </Tab>
                        <Tab eventKey="order" title="Order List">
                            {loading ? (
                                <Typography>Loading...</Typography>
                            ) : orderList.length === 0 ? (
                                <Typography>No data found</Typography>
                            ) : (
                                <div>
                                    <CustomActions 
                                        totalAmount={totalAmount}
                                        showTotalAmount={false}
                                        onShowFilters={() => setModalOpen(true)}
                                        handleModalOpenChange={handleModalOpenChange}
                                        isModalOpen={modalOpen}
                                        setFilteredData={setFilteredData}
                                        data={orderList}
                                        salesExporter={salesExporter}
                                        setTotalAmount={setTotalAmount}
                                        activeTab={activeTab}
                                    />
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Order ID</th>
                                                <th>Transaction ID</th>
                                                <th>Machine UUID</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                {isAdmin && (
                                                    <th>Company Name</th>
                                                )}
                                                <th>Product Name</th>
                                                <th>Product Slot</th>
                                                <th>Product Price</th>
                                                <th>Gateway</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredOrderData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.OrderNo}</td>
                                                    <td>{item.TransactionId}</td>
                                                    <td>{item.Machine.machineUUID}</td>
                                                    <td>{renderDate(item)}</td>
                                                    <td>{renderTime(item)}</td>
                                                    {isAdmin && (
                                                        <td>{item.companyName}</td>
                                                    )}
                                                    <td>{item.Product.name}</td>
                                                    <td>{renderProductSlot(item)}</td>
                                                    <td>{item.Amount}</td>
                                                    <td>{renderGatewayField(item)}</td>
                                                    <td>{renderTransactionStatus(item)}</td>
                                                    <td>
                                                        {isAdmin ? (
                                                            renderAdminMoreButtonField(item, setDetailsOpen, setModalContent)
                                                        ) : (
                                                            renderCustomerMoreButtonField(item, setDetailsOpen, setModalContent)
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                // <List
                                //     resource='orders'
                                //     perPage={25}
                                //     sort={{ field: sortField, order: sortOrder }}
                                //     actions={
                                //         <CustomActions
                                //             showTotalAmount={false}
                                //             onShowFilters={() => setModalOpen(true)}
                                //             handleModalOpenChange={handleModalOpenChange}
                                //             isModalOpen={modalOpen}
                                //             setFilteredData={setFilteredOrderData}
                                //             data={orderList}
                                //             setTotalAmount={setTotalAmount}
                                //             activeTab={activeTab}
                                //         />
                                //     }
                                //     empty={false}
                                //     filter={clientId ? { "client": clientId } : { mode: "commercial" }}
                                // >
                                //     {isAdmin ? <AdminOrderList data={filteredOrderData} renderMoreButton={(record => renderAdminMoreButtonField(record, setDetailsOpen, setModalContent))} sortField={sortField} sortOrder={sortOrder} /> : <ClientOrderList data={filteredOrderData} renderMoreButton={(record => renderCustomerMoreButtonField(record, setDetailsOpen, setModalContent))} sortField={sortField} sortOrder={sortOrder} />}
                                // </List>
                            )}
                        </Tab>
                    </Tabs>
                </div>
                <Modal open={detailsOpen} onClose={() => setDetailsOpen(false)}>
                    <div className={classes.paper}>
                        {typeof modalContent === "string" ? (
                            <pre>{modalContent}</pre>
                        ) : (
                            modalContent
                        )}
                    </div>
                </Modal>
            </_extendLayout>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    paper: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 500,
      maxHeight: '90%',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));

export default SalesTransactionList;
