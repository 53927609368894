import { Button, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { minValue, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import { auth, db } from '../../firebase';
import _extendLayout from '../../layout/_extendLayout';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function EmployeeTypeCreate() {

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState("");

    const dataProvider = useDataProvider();
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
            }
        })

        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            if(!res || !res.data) {
                throw new Error("Document not found");
            }
            if(res.data && res.data.client && res.data.client.id) {
                if(res.data.role == "admin") {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
                setClientId(res.data.client.id)
            }
        } catch (err) {
            console.log("error : ", err)
        }
    };

    const handleSave = async (data) => {
        try {
            const emploTypRef = collection(db, "employeeTypes");
            await addDoc(emploTypRef, {
                name: data.name,
                credit: data.credit,
                client: (data.client == undefined) ? clientId : data.client,
                createdAt: new Date(),
                updateAt: new Date(),
            })
            navigate("/employeeTypes");
        } catch (err) {
            console.log("handle save error: ", err)
        }
    }

    return (
        <>
            <_extendLayout>
                <div className='listMachines'>
                    <CardContent>
                        <CardHeader title="Create Type" />
                        <SimpleForm toolbar={null} onSubmit={handleSave}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextInput label="Employee Type" name="name" fullWidth validate={required("Please enter a employee type.")} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput 
                                        label="Credit" 
                                        name="credit" 
                                        type="number"
                                        fullWidth
                                        validate={[required("Please enter a employee type."), minValue(0, "Credit cannot be less than 0.")]}
                                    />
                                </Grid>
                                {isAdmin ? (
                                    <Grid item xs={12}>
                                        <ReferenceInput source="client" reference="clients" filterToQuery={() => {}}>
                                            <SelectInput
                                                optionText="companyName"
                                                variant="outlined"
                                                fullWidth
                                                validate={required("Please select a client")}
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <CardActions>
                                <Button type="submit" variant="contained" color="primary" style={{ marginTop: "2rem"}}>
                                    Save
                                </Button>
                            </CardActions>
                        </SimpleForm>
                    </CardContent>
                </div>
            </_extendLayout>
        </>
    )
}

export default EmployeeTypeCreate;
