import React, { useEffect, useState } from 'react';
import MachinesList from '../components/Machines/MachinesList';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { useDataProvider, TopToolbar } from 'react-admin';
import _extendLayout from '../layout/_extendLayout';
import { Button } from '@material-ui/core';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';
import jsPDF from 'jspdf';
import "jspdf-autotable";

const VendingMachines = (props) => {

    const dataProvider = useDataProvider();
    
    const [macList, setMacList] = useState(null);
    const [clientId, setClientId] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, (user) => {
            if(user) {
                getCurrentUser(user.uid);
            }

            return () => checkAuth();
        })
    }, [auth]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            const { role, client } = res.data;

            if(role === "admin") {
                getMachines();
            } else {
                getMachines(client.id);
                setClientId(client.id);
            }

            setIsAdmin(res.data.role === "admin");
            
        } catch (err) {
            console.log("error: ", err);
        }
    };

    const getMachines = async (filter) => {
        try {
            const machineFilter = filter
                ? { "client.id": filter } // If filter is provided, filter by client ID
                : {};
            const res = await dataProvider.getList("machines", {
                filter: machineFilter,
                sort: { field: "createdAt", order: "DESC" },
                pagination: { page: 1, perPage: 1000 },
            });
            setMacList(res.data);
        } catch (err) {
            console.log("error:", err);
        }
    };

    const exporter = async (datas) => {
        const headers = [
            "ID",
            "Client ID",
            "Created At",
            "Device ID",
            "Driver Boards",
            "Drop Sensor",
            "Is Deleted",
            "Location",
            "Machine Mode",
            "Machine Model",
            "Motor Turn",
            "Payment Enabled",
            "Payment Methods Ipay88 Email",
            "Payment Methods Ipay88 Is Active",
            "Payment Methods Ipay88 Merchant Code",
            "Payment Methods Ipay88 Merchant Key",
            "Payment Methods Ipay88 Phone Number",
            "Payment Methods Ipay88 User Name",
            "Status Online",
            "Updated At",
            "Version Code",
            "Version Name",
            "Status Offline",
            "Status Error",
            "Client App Key",
            "Client App Secret",
            "Client Is Active",
            "Status Out Of Stock",
            "Enable Extra Spin",
            "MRO One Product",
            "Network Capture Interval",
            "Network Sync Interval",
            "Payment Methods GHL Key",
            "Payment Methods GHL Merchant ID",
            "Payment Methods GHL Terminal ID",
            "Status Terminal Error",
            "Machine Params Group Similar Product",
            "Machine Params Input Cost Center",
            "Machine Params Single Product",
            "Machine Params Spinning Order",
            "Status Terminal Error Description",
            "Status Terminal Error Recovered",
            "Status Terminal Error Title",
            "Limitation Rule",
            "Machine Params Multi Cart",
            "Machine Params Notice",
            "Machine Params Vircle Merchant ID",
            "Machine Params Max Multi Cart Quantity",
            "Machine Params Password",
            "Machine Params Third Party URL",
            "Machine Params Integration Type",
        ];

        let csvRows = [headers.join(",")];

        datas.forEach(e => {
            const formattedCreatedAt = e.createdAt?.toDate
                ? `"${e.createdAt.toDate().toLocaleString()}"`
                : e.createdAt?.seconds
                    ? `"${new Date(e.createdAt.seconds * 1000).toLocaleString()}"`
                    : '""';

            const formattedUpdatedAt = e.updatedAt?.toDate
                ? `"${e.updatedAt.toDate().toLocaleString()}"`
                : e.updatedAt?.seconds
                    ? `"${new Date(e.updatedAt.seconds * 1000).toLocaleString()}"`
                    : '""';

            const commonDetails = [
                e.id,
                e.client?.id ?? "",
                formattedCreatedAt,
                e.deviceID ?? "",
                `"${e.driverBoards?.join(", ") ?? ""}"`,
                e.dropSensor ?? "",
                e.isDeleted ?? "",
                e.location ?? "",
                e.machineMode ?? "",
                e.machineModel ?? "",
                e.motorTurn ?? "",
                `"${e.paymentEnabled?.join(", ") ?? ""}"`,
                e.paymentMethods?.ipay88?.email ?? "",
                e.paymentMethods?.ipay88?.isActive ? "true" : "false",
                e.paymentMethods?.ipay88?.merchantCode ?? "",
                e.paymentMethods?.ipay88?.merchantKey ?? "",
                e.paymentMethods?.ipay88?.phoneNumber ?? "",
                e.paymentMethods?.ipay88?.username ?? "",
                e.status?.online ? "true" : "false",
                formattedUpdatedAt,
                e.versionCode ?? "",
                e.versionName ?? "",
                e.status?.offline ? "true" : "false",
                e.status?.error ?? "",
                e.client?.appKey ?? "",
                e.client?.appSecret ?? "",
                e.client?.isActive ? "true" : "false",
                e.status?.out_of_stock ?? "",
                e.enableExtraSpin ? "true" : "false",
                e.mroOneProduct ?? "",
                e.networkCaptureInterval ?? "",
                e.networkSyncInterval ?? "",
                e.paymentMethods?.ghl?.key ?? "",
                e.paymentMethods?.ghl?.merchantID ?? "",
                e.paymentMethods?.ghl?.terminalID ?? "",
                e.status?.terminal_error?.terminal_error ?? "",
                e.machineParams?.groupSimilarProduct ? "true" : "false",
                e.machineParams?.inputCostCenter ? "true" : "false",
                e.machineParams?.singleProduct ? "true" : "false",
                e.machineParams?.spinningOrder ?? "",
                e.status?.terminal_error?.description ?? "",
                e.status?.terminal_error?.recovered ?? "",
                e.status?.terminal_error?.title ?? "",
                `"${e.limitationRule?.join(", ") ?? ""}"`,
                e.machineParams?.multiCart ? "true" : "false",
                e.machineParams?.notice ?? "",
                e.machineParams?.vircle_merchant_id ?? "",
                e.machineParams?.maxMultiCartQuantity ?? "",
                e.machineParams?.pwd ?? "",
                e.machineParams?.thirdPartyUrl ?? "",
                e.machineParams?.integration?.type ?? "",
            ];
            csvRows.push([...commonDetails].join(","));
        });


        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "machines.csv";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportToPDF = async () => {
        const machineIds = macList.map(item => item.id).join(",");
        const machinesData = await getPrekittingData(machineIds);

        const doc = new jsPDF();

        //set title
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Prekitting List", 10, 10);

        //draw line
        doc.setLineWidth(0.5);
        doc.line(10, 15, 200, 15);

        let yPosition = 20;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text("Company Name:", 10, yPosition);
        doc.setFont("helvetica", "normal");
        doc.text(`${machinesData[0].companyName}`, 40, yPosition); // Use first machine's company name

        const pageWidth = doc.internal.pageSize.width;  // Get the page width
        const generatedDateText = `Generated Date: ${new Date().toLocaleString()}`;
        const generatedDateWidth = doc.getTextWidth(generatedDateText);
        doc.text(generatedDateText, pageWidth - generatedDateWidth - 10, yPosition);
        
        yPosition += 10;

        const marginLeft = 10;
        const marginRight = 10;
        const availableTableWidth = pageWidth - marginLeft - marginRight;  // Full width minus margins

        // Adjust column widths dynamically
        const col1Width = availableTableWidth * 0.61;
        const col2Width = availableTableWidth * 0.13;
        const col3Width = availableTableWidth * 0.13;
        const col4Width = availableTableWidth * 0.13; 

        machinesData.forEach(machine => {
            const { machineUUID, location } = machine;

            const pageHeight = doc.internal.pageSize.height;
            const rowHeight = 10;
            const remainingSpace = pageHeight - yPosition;
            
            if (remainingSpace < rowHeight * 6) { 
                doc.addPage();
                yPosition = 10;
    
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(`${location || "-"} | [${machineUUID}]`, 10, yPosition);
    
                yPosition += 5;
    
            } else {
                // Set company location and UUID for the first page or the same page
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(`${location || "-"} | [${machineUUID}]`, 10, yPosition);
    
                yPosition += 5;
            }

            doc.autoTable({
                head: [['Product Name', 'Max Capacity', 'Stock Remaining', 'Stock Refill']],
                body: machine.slots.map(item => [
                    item.productName,
                    item.maxCapacity,
                    item.totalQuantity,
                    item.predict
                ]),
                startY: yPosition,
                headStyles: { fontStyle: 'bold', fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                margin: { top: 9, left: 9, right: 9, bottom: 9 },
                didDrawPage: function (data) {
                    const lastRow = data.cursor.y;
                    const pageHeight = doc.internal.pageSize.height;

                    if(lastRow < pageHeight - 20) {
                        doc.setLineWidth(0.3);
                        doc.line(10, lastRow, 200, lastRow);
                    }
                },
                columnStyles: {
                    0: { cellWidth: col1Width, halign: 'left' },
                    1: { cellWidth: col2Width, halign: 'right' },
                    2: { cellWidth: col3Width, halign: 'right' },
                    3: { cellWidth: col4Width, halign: 'right' },
                },
            });

            yPosition = doc.lastAutoTable.finalY + 5;
        });
        
        doc.save("prekitting_details_all_machines.pdf");
    };

    const getPrekittingData = async (machineIds) => {
        try {
            let token = localStorage.getItem("token");

            const res = await axios.get(`https://api-dev-v2.vechnology.com.my/api/slot/prekittingMachinesList?machineIds=${machineIds}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            return res.data.result;
        } catch (error) {
            console.log("Error on fetching prekitting list");
        }
    };

    const CustomActions = ({ searchInput, ...props }) => (
        <TopToolbar style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            {searchInput}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button color="primary" startIcon={<FileDownloadIcon />} onClick={exportToPDF}>
                    Export Prekitting List
                </Button>
                <Button color="primary" startIcon={<FileDownloadIcon />} onClick={exporter}>
                    Export
                </Button>
            </div>
        </TopToolbar>
    );
    

    return (
        <>
            {macList ? (
                <_extendLayout>
                    <MachinesList macList={macList} {...props} actions={<CustomActions />} clientId={clientId} isAdmin={isAdmin} />
                </_extendLayout>
            ) : (
                <_extendLayout>
                    <p>Loading...</p>
                </_extendLayout>
            )}
        </>
    );
}

export default VendingMachines;