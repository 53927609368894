import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Table } from "react-bootstrap";
import { db } from '../../firebase';
import { FaCheck } from 'react-icons/fa';
import { getAuth, signOut } from 'firebase/auth';
import axios from 'axios';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { Button } from '@material-ui/core';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const PrekittingList = (props) => {
    const machineId = props.machineId;
    const [combinedData, setCombinedData] = useState([]);
    const [updatedQuantities, setUpdatedQuantities] = useState({});
    const [updatedDamage, setUpdatedDamage] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [companyName, setCompanyName] = useState("");

    const { deviceID, machineUUID, machineName, machineMode, location } = props.macDetails || {};

    useEffect(() => {
        const fetchData = async () => {
            const auth = getAuth();
            
            if(auth.currentUser) {
                if(machineId) {
                    setIsAdmin(JSON.parse(auth.currentUser.reloadUserInfo.customAttributes).role === "admin");
                    const slotData = await getSlotData(machineId);
                    setCombinedData(slotData)
                    // const storageData = await getStorageData(machineId);
                    // combineData(slotData, storageData);
                }
            } else {
                await signOut(auth);
                window.location.href("/");
            }
        };

        fetchData();
    }, [machineId]);

    const getSlotData = async (machineId) => {
        try {
            let token = localStorage.getItem("token");
            
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/slot/prekittingList`, {
                params: {
                    machineId: machineId
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            setCompanyName(res.data.result.companyName);

            return res.data.result.data;
        } catch (error) {
            console.log("Error fetching slot data:", error);
            return {};
        }
    };

    const getStorageData = async (machineId) => {
        try {
            const storageRef = collection(db, "machines", machineId, "storages");
            const querySnapshot = await getDocs(storageRef);

            const storageData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(), // Storage data
            }));

            console.log("Storage data:", storageData);
            return storageData;
        } catch (error) {
            console.log("Error fetching storage data:", error);
            return [];
        }
    };

    const combineData = (slotData, storageData) => {

        const combined = Object.keys(slotData).map((productId) => {
            const storage = storageData.find((s) => s.product.id === productId) || {};

            return {
                productId,
                ...slotData[productId],
                storage
            };
        });
        
        setCombinedData(combined)
    };

    const handleQuantityChange = (id, newQuantity) => {

        setUpdatedQuantities(prev => ({
            ...prev,
            [id]: newQuantity === "" ? "" : newQuantity
        }));
    };

    const handleDamageChange = (id, newQuantity) => {

        setUpdatedDamage(prev => ({
            ...prev,
            [id]: newQuantity === "" ? "" : newQuantity,
        }));
    };

    const handleSave = async (id) => {
        console.log(id);
        const newQuantity = updatedQuantities[id];
        if (!newQuantity) return;
        console.log(newQuantity)
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        //set title
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Prekitting List", 10, 10);

        //draw line
        doc.setLineWidth(0.5);
        doc.line(10, 15, 200, 15);

        //set company details (side by side)
        doc.setFontSize(10);
        doc.text("Company Name:", 10, 20);
        doc.setFont("helvetica", "normal");
        doc.text(`${companyName}`, 40, 20);

        doc.setFont("helvetica", "bold");
        doc.text("Generated Date:", 130, 20);
        doc.setFont("helvetica", "normal");
        doc.text(`${new Date().toLocaleString()}`, 160, 20);

        //set company location and UUID
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`${location || "-"} | [${machineUUID}]`, 10, 30);

        //set header for table
        doc.autoTable({
            head: [['Product Name', 'Max Capacity', 'Stock Remaining', 'Stock Refill']],
            body: combinedData.map(item => [
                item.productName,
                item.maxCapacity,
                item.totalQuantity,
                item.predict
            ]),
            startY: 40,
            headStyles: { fontStyle: 'bold', fillColor: [255, 255, 255], textColor: [0, 0, 0] },
            columnStyles: {
                0: { cellWidth: 120 },
                1: { cellWidth: 25 },
                2: { cellWidth: 25 },
                3: { cellWidth: 25 },
            },
            margin: { top: 0, left: 9, right: 9, bottom: 0 },
            didDrawPage: function (data) {
                const lastRow = data.cursor.y;

                doc.setLineWidth(0.3);
                doc.line(10, lastRow, 200, lastRow);
            },
        });

        doc.save("prekitting_detail.pdf");
    };

    return (
        <>
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px" }}>
                    <h4>Prekitting Data</h4>
                    <Button color="primary" startIcon={<FileDownloadIcon />} onClick={exportToPDF}>
                        Export
                    </Button>
                </div>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Max Capacity</th>
                            <th>Stock Remaining</th>
                            <th>Stock Refill</th>
                        </tr>
                    </thead>
                    <tbody>
                        {combinedData?.length > 0 ? (
                            combinedData.map((items) => (
                                <tr key={items.productId}>
                                    <td className="fontHandle">{items.productName}</td>
                                    <td className="fontHandle">{items.maxCapacity}</td>
                                    <td className="fontHandle">{items.totalQuantity}</td>
                                    <td className="fontHandle">{parseInt(items.maxCapacity) - parseInt(items.totalQuantity)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" style={{ textAlign: "center", padding: "10px", fontStyle: "italic" }}>
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default PrekittingList;
