import React from "react";
import { Table } from "react-bootstrap";

import "../components/css/LatestTrans.css";

const ProductsPerTable = ({ productRanking, isLoading }) => {

    return(
        <>
            <div className="proTableDiv">
                <h6 className="lblProductTable">Product Ranking</h6>

                <Table striped bordered hover responsive size="sm">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Frequency</th>
                            <th>Sales</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="3" style={{ textAlign: "center", padding: "10px", fontStyle: "italic", fontWeight: "bold" }}>
                                    Loading...
                                </td>
                            </tr>
                        ) : productRanking.length > 0 ? (
                            productRanking.map((row) => (
                                <tr key={row.productId}>
                                    <td className="fontHandle">{row.productName}</td>
                                    <td className="fontHandle">{row.frequency}</td>
                                    <td className="fontHandle">{row.totalSales.replace("RM", "").trim()}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" style={{ textAlign: "center", padding: "10px", fontStyle: "italic" }}>
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default ProductsPerTable;