import { Button, CardHeader } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react'
import { Datagrid, ExportButton, List, NumberField, Pagination, ReferenceField, TextField, TopToolbar, useDataProvider, useListContext } from 'react-admin';
import TransactionFilter from '../Filters/TransactionFilter';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import _extendLayout from '../../layout/_extendLayout';

const TransactionEmployeeList = (props) => {
    const dataProvider = useDataProvider();

    const [isAdmin, setIsAdmin] = useState(false);
    const [transEmpList, setTransEmpList] = useState([]);
    const [clientId, setClientId] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [groupedData, setGroupedData] = useState([]);

    const roundDecimal = (value) => Math.round(value * 100) / 100;

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                const { filter: currentFilter, admin } = await getCurrentUser(user.uid);
                await getTransactionByEmployee(currentFilter, admin);
            }
        });

        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });

            const admin = res.data.role === "admin";
            setIsAdmin(admin);

            let currentFilter = admin
                ? { mode: "mro" }
                : {
                    client: res.data?.client?.id,
                    mode: "mro",
                };

            if(!admin) setClientId(res.data?.client?.id);
            
            return { filter: currentFilter, admin };
        } catch (err) {
            console.log(err);
        }
    };
    
    const getTransactionByEmployee = async (filter, admin) => {
        try {
            const res = await dataProvider.getList("transactions", { filter, pagination: { page: 1, perPage: 1000 }, });
            const transactions = res.data;
            
            if(admin) {
                const groupedTransactions = transactions.reduce((acc, transaction) => {
                    const employeeId = transaction.employee?.id || "";
    
                    if (!acc[employeeId]) {
                        acc[employeeId] = [];
                    }
    
                    acc[employeeId].push(transaction);
                    return acc;
                }, {});

                const groupedArray = Object.values(groupedTransactions)
                
                const data = Object.entries(groupedTransactions).flatMap(([id, arr]) => {
                    let totalQty = 0;
                    let totalCash = 0;
                    let totalCredit = 0;

                    const { employee, client, machine, date, status } = arr[0];
                    
                    arr.forEach(({ quantity = 0, amount = 0, mode }) => {
                        totalQty += quantity;
                        if(mode === "mro") {
                            totalCredit += amount;
                        } else {
                            totalCash += amount;
                        }
                    });
                    
                    return {
                        id, 
                        client: client ?? "",
                        employee: {
                            ...employee,
                            fullName: `${employee?.fullName}` ?? "",
                        },
                        totalQty,
                        totalTransactions: arr.length,
                        totalCash: roundDecimal(totalCash),
                        totalCredit: roundDecimal(totalCredit),
                        machine,
                        date,
                        status,
                    };
                });
                
                setGroupedData(data);
                setTransEmpList(data);
                setFilteredData(data);
            } else {
                const groupedTransactions = transactions.reduce((acc, transaction) => {
                    const employeeId = transaction.employee?.id;
                    if(!employeeId) return acc;
    
                    if (!acc[employeeId]) {
                        acc[employeeId] = [];
                    }
    
                    acc[employeeId].push(transaction);
                    return acc;
                }, {});

                const groupedArray = Object.values(groupedTransactions)
                
                const dataPromise = Object.entries(groupedTransactions).map(async ([id, arr]) => {
                    let totalQty = 0;
                    let totalCash = 0;
                    let totalCredit = 0;
    
                    const { employee, client, machine, date, status } = arr[0];
    
                    const employeeDetails = await getEmployeeDetails(employee?.id);
                    
                    arr.forEach(({ quantity = 0, amount = 0, mode }) => {
                        totalQty += quantity;
                        if (mode === "mro") {
                          totalCredit += amount;
                        } else {
                          totalCash += amount;
                        }
                    });
    
                    return { id, client, employee: { ...employee, ...employeeDetails }, totalQty, totalTransactions: arr.length, totalCash: roundDecimal(totalCash), totalCredit: roundDecimal(totalCredit), machine, date, status };
                });
                const data = await Promise.all(dataPromise);
                
                setGroupedData(data);
                setTransEmpList(data);
                setFilteredData(data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getEmployeeDetails = async (employeeId) => {
        if (!employeeId) {
            console.error("Employee ID is missing");
            return null;
        }
        try {
            const res = await dataProvider.getOne("employees", { id: employeeId });
            return res.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };

    const handleModalOpenChange = useCallback((isOpen) => {
                setIsModalOpen(isOpen);
    }, []);

    const CustomActions = React.memo(({ ...props }) => (
        <TopToolbar style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
                {}
            </div>
            <Button
                startIcon={<FilterListIcon />}
                onClick={() => props.onShowFilters()}
                variant="outlined"
                color="primary"
                style={{ border: "none" }}
            >
                Show Filters
            </Button>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <TransactionFilter
                    setFilteredData={props.setFilteredData}
                    allData={props.data}
                    onModalOpenChange={handleModalOpenChange}
                    modalOpen={isModalOpen}
                />
                <ExportButton label="Export" exporter={traEmpExporter} />
            </div>
        </TopToolbar>
    ));

    const traEmpExporter = async (datas) => {
        
        const headers = [
            "ID",
            "Client",
            "Employee Cost Center",
            "Employee Number",
            "Employee Full Name",
            "Employee ID",
            "Employee Params Tag",
            "Employee Params Value",
            "Total Quantity",
            "Total Transactions",
            "Total Cash",
            "Total Credit",
            "Employee Params",
        ];

        let csvRows = [headers.join(",")];

        const filterData = datas.filter((e) => e.client === clientId);

        Object.values(filterData).forEach((e) => {
            const tag = e.employee?.params.map((p) => p.tag ?? "").join(", ");
            const value = e.employee?.params.map((p) => p.value ?? "").join(", ");

            const formattedParams = e.employee?.params
                ?.map((p) => `tag: ${p.tag ?? ""}, value: ${p.value ?? ""}`)
                .join("; ");

            const quotedFormattedParams = `"${formattedParams}"`;
            
            const commonDetails = [
                e.id,
                e.client ?? "",
                e.employee?.costCenter ?? "",
                e.employee?.employeeNumber ?? "",
                e.employee?.fullName ?? "",
                e.employee?.id ?? "",
                tag,
                value,
                e.totalQty, //total qty
                e.totalTransactions, //total transactions
                e.totalCash, //total cash
                e.totalCredit, //total credit
                quotedFormattedParams
            ];
            csvRows.push([...commonDetails].join(","));
        });

        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "MRO Report Employees.csv";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <_extendLayout isModalOpen={isModalOpen}>
                <div style={{ width: '90%' }}>
                    <CardHeader title="MRO List By Employee" />
                    {transEmpList.length <= 0 ? (
                        <p>Loading...</p>
                    ) : (
                        <List
                            {...props}
                            resource="transactions"
                            filter={{ "client": clientId, mode: "mro" }}
                            sort={{ field: 'date', order: 'DESC' }}
                            perPage={25}
                            pagination={<Pagination />}
                            actions={
                                <CustomActions
                                    onShowFilters={() => setIsModalOpen(true)} // Open the Drawer
                                    handleModalOpenChange={handleModalOpenChange} // Pass callback for modal state
                                    isModalOpen={isModalOpen}
                                    setFilteredData={setFilteredData}
                                    data={transEmpList}
                                    traEmpExporter={traEmpExporter}
                                />
                            }
                        >
                            <Datagrid data={groupedData} bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                                <TextField label="Employee Name" source="employee.fullName" />
                                {isAdmin && (
                                    <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                        <TextField source="companyName" />
                                    </ReferenceField>
                                )}
                                <TextField label="Total Transactions" source="totalTransactions" />
                                <NumberField label="Total Spending (Credit)" source="totalCredit" />
                            </Datagrid>
                        </List>
                    )}
                </div>
            </_extendLayout>
        </>
    );
}

export default TransactionEmployeeList;
