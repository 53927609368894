import React from "react";
import Table from "react-bootstrap/Table";
import { RiPieChart2Line } from "react-icons/ri";

import "../components/css/MachineHealth.css";

const MachineHealth = ({ machinesDetails }) => {
    return(
        <>
            <h6 className="lblMachineHealth">List of Machines</h6>
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr>
                        <th>Machines</th>
                        <th>Stock</th>
                        <th>Error</th>
                        <th>Product Name</th>
                        <th>Last Sold</th>
                    </tr>
                </thead>
                <tbody>
                    {machinesDetails?.length > 0 ? (
                        machinesDetails.map((i) => (
                            <tr key={i.machine_UUID}>
                                <td className="fontHandle">
                                    <div className="healthMachineIcon">
                                        <div>{i.machine_UUID}</div>
                                        <div className={i.online_status === true ? "greenDot" : "redDot"}></div>
                                    </div>
                                </td>
                                <td className="fontHandle">
                                    <div className="healthMachineIcon">
                                        <div>{i.stock}</div>
                                        <div>
                                            <RiPieChart2Line className={i.stock_status === "good" ? "green-icon" : i.stock_status === "medium" ? "yellow-icon" : "red-icon"} />
                                        </div>
                                    </div>
                                </td>
                                <td className="fontHandle">
                                    <div className="healthMachineIcon">
                                        <div>{i.health}</div>
                                        <div>
                                            <RiPieChart2Line className={i.health_status === "good" ? "green-icon" : i.health_status === "medium" ? "yellow-icon" : "red-icon"} />
                                        </div>
                                    </div>
                                </td>
                                <td className="fontHandle">
                                    {i.product_name.replace("mineral ", "m.").replace("drinking ", "d.").replace("black ", "b.")}
                                </td>
                                <td className="fontHandle">
                                    {i.last_purchase}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" style={{ textAlign: "center", padding: "10px", fontStyle: "italic" }}>
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

export default MachineHealth;