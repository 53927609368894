import React, { useState, useRef, useEffect } from 'react'
import NavBar from '../components/NavBar'
import SideBar from '../components/SideBar'
import Notification from "../components/Notification";
import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebase';

const _extendLayout = ({ children, isModalOpen }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    const notificationRef = useRef(null);
    const [results, setResult] = useState(null);
    const [unReadCount, setUnReadCount] = useState(0);
    const [userAccess, setUserAccess] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const db = getFirestore();

    const sidebarStyle = isModalOpen
        ? { opacity: 0.5, pointerEvents: 'none', transition: 'opacity 0.3s ease' }
        : {};

    const toggledMoibleSidebar = () => {
        setToggled(!toggled);
    };

    const handleMouseEnter = () => {
        setCollapsed(false);
    };

    const handleMouseLeave = () => {
        setCollapsed(true);
    };

    const toggleNotificationList = () => {
        setIsNotificationOpen(!isNotificationOpen)
    };

    const handleClickOutside = (event) => {
        if(notificationRef.current && !notificationRef.current.contains(event.target)) {
            setIsNotificationOpen(false);
        }
    };

    const getFirebaseToken = async () => {
        try {
            const currentUser = auth.currentUser;

            if(currentUser) {
                const token = await currentUser.getIdToken(true);
                localStorage.setItem("token", token);
                return token;
            }
        } catch (error) {
            console.log("Error getting Firebase token:", error);
            return null;
        }
    }

    const fetchNotifications = async () => {
        try {
            const token = await getFirebaseToken();
            if(!token) return;

            const response = await axios.get(`https://api-dev-v2.vechnology.com.my/api/notification`, {
                params: {
                    skip: 0,
                    limit: 10,
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            if(response.data) {
                setResult(response.data)
                setUnReadCount(response.data.result.unRead_count)
            } else {
                console.log("No data found");
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchUserAccess = async () => {
        try {
            const currentUser = auth.currentUser;
            if(!currentUser) return;

            const userDocRef = doc(db, "users", currentUser.uid);
            const userSnapshot = await getDoc(userDocRef);

            if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                const access = userData.access || [];
                
                setUserAccess(access);
                setIsAdmin(userData.role === "admin");
                localStorage.setItem("userAccess", JSON.stringify(access));

                setUserDetails({
                    firstName: userData.firstName || "",
                    lastName: userData.lastName || "",
                    email: userData.email,
                    companyName: userData?.client?.companyName || "",
                });
            } else {
                console.log("No access field found for the user.");
            }
        } catch (err) {
            console.log('Error fetching user access:', err)
        }
    };

    const refreshTokenBeforeExpiry = async () => {
        try {
            const currentUser = auth.currentUser;
            if(!currentUser) return;

            const idTokenResult = await currentUser.getIdTokenResult();
            const expirationTime = new Date(idTokenResult.expirationTime).getTime();
            const refreshTime = expirationTime - Date.now() - 5 * 60 * 1000;

            if(refreshTime > 0) {
                setTimeout(async () => {
                    await getFirebaseToken();
                    refreshTokenBeforeExpiry();
                }, refreshTime);
            } else {
                await getFirebaseToken();
                refreshTokenBeforeExpiry();
            }
        } catch (error) {
            console.log("error refresh token : ", error)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await fetchNotifications();
                await refreshTokenBeforeExpiry();
                await fetchUserAccess();
            }

            const storedAccess = localStorage.getItem("userAccess");
            if(storedAccess) {
                setUserAccess(JSON.parse(storedAccess));    
            }
        });
    
        return () => checkAuth();
    }, []);
    
    return (
        <>
            <div className="dashboard">
                <div>
                    <div style={{ display:"flex", height:"100vh" }}>
                        <div 
                            className="sidebar-container" 
                            style={{
                                ...(isModalOpen
                                    ? { opacity: 0.5, pointerEvents: 'none', transition: 'opacity 0.3s ease' }
                                    : {})
                            }}>
                            <SideBar 
                                setBroken={setBroken} 
                                toggled={toggled} 
                                setToggled={setToggled} 
                                collapsed={collapsed} 
                                handleMouseEnter={handleMouseEnter} 
                                handleMouseLeave={handleMouseLeave}
                                userAccess={userAccess}
                                isAdmin={isAdmin}
                            />
                        </div>
                        <div style={{ flexGrow: 1, minWidth: "100%", maxWidth: "100%" }}>
                            <div className="navbar-container">
                                <NavBar 
                                    broken={broken} 
                                    toggledMoibleSidebar={toggledMoibleSidebar} 
                                    toggleNotificationList={toggleNotificationList} 
                                    unReadCount={unReadCount}
                                    userDetails={userDetails}
                                />
                            </div>
                            {isNotificationOpen && (
                                <Notification 
                                    ref={notificationRef} 
                                    data={results} 
                                    setUnReadCount={setUnReadCount} 
                                />
                            )}
                            <div className="content-container">
                                <div className='container'>
                                    <div className='machineAreas'>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default _extendLayout
