import React from "react";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';

const Copyright = (props) => {
    const pkgJson = require('../../package.json');
    
    return(
        <>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="https://vechnology.com.my/" style={{ textDecoration:"none" }}>
                    Vechnology
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
                {"@"} v{pkgJson.version}
            </Typography>
        </>
    )
};

export default Copyright;